import React, {useContext} from 'react';
import Context from '../../context/Context';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';

const Carriel = () => {
    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;
    }

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };
    return (
        <div className='div-dibujo'>
            <svg    version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                    x="0px" y="0px"
                    width="576px" height="507.56px" 
                    viewBox="0 0 576 507.56" 
                    onClick={obtenetID}
            >
                <g>
                    <g>
                        <path fill="#FFFFFF" stroke="#000000" d="M327.51,502.39c-34.5,0-68.83-12.58-101.54-25.95
                            C167.88,452.69,101.81,423.15,53,373.02c-20.28-20.83-33.79-41.42-41.3-62.96c-9.14-26.2-8.52-53.92,1.69-76.04
                            c8.12-17.6,21.72-31.32,34.87-44.59c4.96-5,10.08-10.18,14.75-15.41c15.31-17.17,27.73-37.37,39.74-56.91
                            c9.54-15.52,19.4-31.57,30.8-46.32c32.1-41.54,73.91-63.25,111.82-58.09l-1.68,12.31c-33.44-4.55-70.96,15.4-100.31,53.38
                            c-10.99,14.23-20.68,29.99-30.05,45.23c-12.29,20-25,40.68-41.05,58.67c-4.89,5.48-10.13,10.77-15.2,15.89
                            c-12.96,13.08-25.21,25.43-32.42,41.05c-8.87,19.23-9.32,43.56-1.23,66.74c6.9,19.79,19.49,38.89,38.47,58.39
                            c47.15,48.42,111.84,77.31,168.77,100.59c40.51,16.57,83.51,31.89,124.59,21.75c35.47-8.75,64.22-36.04,85.3-58.83
                            c44.77-48.4,81.3-103.38,108.58-163.41l11.31,5.14c-27.83,61.24-65.1,117.33-110.77,166.71c-22.26,24.07-52.78,52.92-91.45,62.46
                            C348.01,501.28,337.75,502.38,327.51,502.39z"/>
                    </g>
                    <g>
                        <path fill="#FFFFFF" stroke="#000000" d="M559.88,280.76c0,0,23.21-29.88,6.54-42.2
                            s-33.54,6.15-33.54,6.15L559.88,280.76z"/>
                    </g>
                    <g>
                        <path fill="#FFFFFF" stroke="#000000" d="M240.86,16.89c0,0-159.41,144.86-104.44,234.7
                            c54.96,89.85,205.75,183.46,273.99,143.25c80.46-47.41,139.13-139.65,139.13-139.65L240.86,16.89z"/>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" d="M561.66,281.77c-8.19,13.92-22.83,33.55-24.97,36.52
                                    c-0.02,0.04-0.02,0.04-0.04,0.07c-0.45,0.6-0.89,1.26-1.34,1.85c-1.94,2.8-3.9,5.54-5.89,8.25c-1.11,1.56-2.23,3.11-3.37,4.65
                                    c-1.54,2.12-3.08,4.24-4.66,6.34c-13.76,18.38-28.44,35.63-46.23,50.89c-13.44,11.49-27.95,22.92-44.35,29.58
                                    c-1.28,0.54-2.54,1.05-3.85,1.47c-6.34,2.26-12.97,3.78-19.87,4.31c-19.57,1.51-39.34-2.63-57.86-8.67
                                    c-20.07-6.58-40.14-15.52-59.51-26.45c-1.34-0.74-2.62-1.5-3.91-2.26c-19.75-11.44-38.72-24.92-56.15-39.95
                                    c-1.12-0.95-2.21-1.88-3.27-2.85c-24.45-21.54-45.72-46.1-61.77-72.34c-12.38-20.22-13.88-43.24-8.8-66.79
                                    c0.3-1.41,0.64-2.8,0.98-4.18c8.74-35.2,31.66-71.25,54.5-100.51c1.18-1.51,2.37-3.03,3.59-4.52c3.07-3.81,6.1-7.55,9.1-11.07
                                    c0.37-0.46,0.79-0.93,1.16-1.38c0.47-0.54,0.91-1.05,1.37-1.59c1.5-1.81,3.02-3.56,4.49-5.23c2-2.24,3.95-4.4,5.81-6.48
                                    c5.95-6.64,11.44-12.43,16.16-17.24c0.78-0.84,1.56-1.58,2.3-2.34c0.72-0.73,1.44-1.46,2.14-2.15
                                    c7.17-7.16,11.64-11.21,11.64-11.21l297.6,229.32C568.31,269.05,565.82,274.65,561.66,281.77z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path fill="#FFFFFF" stroke="#000000" d="M282.79,300.1c0,0-49.11,20.71-61.58,56.33
                                        c-12.47,35.62-1.15,76.72-1.15,76.72s38.96-4.59,65.95-37.77c26.99-33.17,31.83-71.47,31.83-71.47L282.79,300.1z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path fill="#FFFFFF" stroke="#000000" d="M281.68,304.85c0,0-43.86,18.49-55,50.31
                                        c-11.14,31.81-1.03,68.52-1.03,68.52s34.8-4.1,58.91-33.73c24.11-29.63,28.43-63.83,28.43-63.83L281.68,304.85z"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" d="M561.66,281.77c-8.19,13.92-22.83,33.55-24.97,36.52
                                    c-0.02,0.04-0.02,0.04-0.04,0.07c-0.45,0.6-0.89,1.26-1.34,1.85c-1.94,2.8-3.9,5.54-5.89,8.25c-1.11,1.56-2.23,3.11-3.37,4.65
                                    c-1.54,2.12-3.08,4.24-4.66,6.34L211.3,101.7c1.18-1.51,2.37-3.03,3.59-4.52c3.07-3.81,6.1-7.55,9.1-11.07
                                    c0.37-0.46,0.79-0.93,1.16-1.38c0.47-0.54,0.91-1.05,1.37-1.59c1.5-1.81,3.02-3.56,4.49-5.23c2-2.24,3.95-4.4,5.81-6.48
                                    c5.95-6.64,11.44-12.43,16.16-17.24c0.78-0.84,1.56-1.58,2.3-2.34c0.72-0.73,1.44-1.46,2.14-2.15L561.66,281.77z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" d="M295.78,286.45l-3.98-1.15c0.36-1.18,0.65-2.4,0.89-3.61
                                    c2.82-13.87-0.86-28.8-9.95-39.71c-10.97-13.18-29.18-20.87-55.66-23.45c-5.46-0.53-11.08-0.88-16.49-1.24
                                    c-15.64-0.98-31.78-2-46.82-7.39c-2.99-1.05-5.62-2.22-7.94-3.49c0.3-1.41,0.64-2.8,0.98-4.18c2.35,1.39,5.12,2.65,8.35,3.78
                                    c14.49,5.2,30.37,6.17,45.71,7.17c5.47,0.35,11.09,0.69,16.62,1.27c27.62,2.68,46.73,10.8,58.42,24.89
                                    c10.3,12.35,14.19,29.37,10.45,44.97C296.2,285.01,295.96,285.74,295.78,286.45z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" d="M430.8,419.92c-1.28,0.54-2.54,1.05-3.85,1.47
                                    c-1.73-3.58-3.34-7.46-4.84-11.67c-4.04-11.39-6.8-22.59-8.23-33.35c-0.55-4.51-0.99-9.15-1.36-13.65
                                    c-1.15-13.91-2.4-28.32-9.69-39.9c-6.77-10.84-19.17-18.84-33.13-21.52c-11.85-2.25-26.99,2.75-39.22,7.4
                                    c-1.6,0.59-3.17,1.21-4.66,1.77l-1.47-3.85c0.74-0.28,1.43-0.55,2.2-0.87c13.05-4.98,30.12-11.15,43.92-8.52
                                    c15.32,2.9,28.36,11.44,35.87,23.39c7.85,12.44,9.08,27.36,10.32,41.76c0.35,4.44,0.75,9.06,1.33,13.44
                                    c1.36,10.47,4.08,21.42,8.01,32.5C427.51,412.54,429.12,416.43,430.8,419.92z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" d="M290.93,280.47l38.75,27.55c3.64,2.59,4.59,7.58,2.17,11.33
                                    l-5.36,8.29c-2.56,3.95-7.88,5.01-11.75,2.33l-40.23-27.85c-4.07-2.82-4.8-8.53-1.58-12.29l6.85-7.98
                                    C282.58,278.56,287.42,277.97,290.93,280.47z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" d="M526.04,333.11c-1.54,2.12-3.08,4.24-4.66,6.34L211.3,101.7
                                    c1.18-1.51,2.37-3.03,3.59-4.52L526.04,333.11z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" d="M536.68,318.29c-0.02,0.04-0.02,0.04-0.04,0.07
                                    c-0.45,0.6-0.89,1.26-1.34,1.85c-1.7-4.37-5.23-8.48-9.25-11.2c-3.38-2.34-7.19-4.11-10.85-5.89c-4.4-2.08-8.95-4.23-12.87-7.28
                                    c-8.51-6.52-13.64-16.97-13.69-27.54c-12.17,4.78-27.3,1.65-36.61-7.66c-3.83-3.85-6.62-8.49-9.35-12.99
                                    c-1.88-3.08-3.81-6.25-6.04-9.15c-5.3-6.74-14.1-12.29-21.95-9.42l-1.16,0.42l-0.23-1.24c-1.34-8.3-9.15-13.64-13.67-16.02
                                    c-3.11-1.65-6.43-3.04-9.67-4.32c-3.72-1.53-7.56-3.08-11.15-5.15c-11.14-6.54-19.44-17.66-22.21-30.22
                                    c-8.98,3.39-19.37-1.98-25.89-9.11c-2.9-3.16-5.39-6.75-7.84-10.17c-3.09-4.38-6.28-8.91-10.23-12.55
                                    c-7.08-6.5-16.9-9.9-26.95-9.35l-0.95,0.06l-0.15-0.96c-0.94-7.92-7.09-15.6-16.82-21.02c-4.23-2.36-8.83-4.26-13.23-6.1
                                    c-4.39-1.78-8.92-3.64-13.17-5.96c-6.02-3.32-11.55-7.65-16.22-12.68c0.47-0.54,0.91-1.05,1.37-1.59
                                    c4.59,4.93,9.97,9.17,15.89,12.43c4.13,2.3,8.58,4.12,12.88,5.89c4.49,1.84,9.19,3.75,13.53,6.17
                                    c9.93,5.59,16.34,13.37,17.74,21.66c10.25-0.33,20.21,3.25,27.52,9.93c4.11,3.78,7.35,8.39,10.49,12.85
                                    c2.42,3.4,4.88,6.88,7.7,10c6.29,6.85,16.36,12.04,24.68,8.15l1.21-0.58l0.24,1.3c2.35,12.46,10.4,23.57,21.47,30.07
                                    c3.46,1.95,7.22,3.5,10.9,5c3.27,1.31,6.68,2.7,9.84,4.43c4.63,2.5,12.54,7.85,14.5,16.32c8.54-2.31,17.69,3.44,23.17,10.53
                                    c2.3,2.95,4.28,6.19,6.17,9.33c2.66,4.37,5.4,8.92,9.03,12.56c9.12,9.15,24.17,11.94,35.77,6.63l1.57-0.71l-0.08,1.68
                                    c-0.46,10.46,4.44,20.96,12.84,27.42c3.77,2.9,8.19,5.03,12.48,7.05c3.75,1.79,7.62,3.64,11.16,6.02
                                    C531.16,309.97,534.69,313.93,536.68,318.29z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path fill="#FFFFFF" stroke="#000000" d="M224.45,32.16c0,0,15.02-34.73,33.84-26.05
                            s10.77,32.36,10.77,32.36L224.45,32.16z"/>
                    </g>
                </g>
            </svg>

            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default Carriel