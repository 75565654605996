import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import Poncho from './components/dibujos svg/Poncho';
import Mochila from './components/dibujos svg/Mochila';
import Sombrero from './components/dibujos svg/Sombrero';
import Carriel from './components/dibujos svg/Carriel';
import DibujoPrueba from './components/dibujos svg/DibujoPrueba';
import Layout from './Layout/Layout';
import State from './context/State';

import './App.scss';

const App = () => {
    return (
        <State>
            <Router>
                <Routes>
                    <Route element={<Layout />}>
                        <Route exac path='/' element={<DibujoPrueba />}/>
                        <Route exac path='/mochila' element={<Mochila />}/>
                        <Route exac path='/carriel' element={<Carriel />}/>
                        <Route exac path='/sombrero' element={<Sombrero />}/>
                    </Route>
                </Routes>
            </Router>
        </State>
    )
}

export default App