
import { COLOR } from '../types/index';

export default (state, action) => {
    switch (action.type) {
        case COLOR:
            return{
                colorObtenido: action.payload
            }          
    
        default:
            return state;
    }
}   