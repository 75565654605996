import React, {useContext} from 'react';

import Context from '../../context/Context';
import {saveAs} from 'file-saver';

import './PaletaColores.scss';

const PaletaColores = () => {
    const stateColor = useContext(Context);
    const{ obtenerColor } = stateColor;

    const ObtenerColor = (e) => {        
        const colorObtenido = e.target.attributes.value;
        obtenerColor(colorObtenido.value);
    }

    const estilos = [
        {
            background: "#FEFFDC",
            border: "1px solid #808080"
        },
        {
            background: "#FFFC99",
            border: "1px solid #808080"
        },
        {
            background: "#FFC481",
            border: "1px solid #808080"
        },
        {
            background: "#FF6CC5",
            border: "1px solid #808080"
        },
        {
            background: "#FF84F6",
            border: "1px solid #808080"
        },
        {
            background: "#FA5457",
            border: "1px solid #808080"
        },
        {
            background: "#FA8925",
            border: "1px solid #808080"
        },
        {
            background: "#F6D51F",
            border: "1px solid #808080"
        },
        {
            background: "#01B4BC",
            border: "1px solid #808080"
        },
        {
            background: "#5FA55A",
            border: "1px solid #808080"
        },
        {
            background: "#BDEDE1",
            border: "1px solid #808080"
        },
        {
            background: "#F7529F",
            border: "1px solid #808080"
        },
        {
            background: "#35EEE0",
            border: "1px solid #808080"
        },
        {
            background: "#F9EEE0",
            border: "1px solid #808080"
        },
        {
            background: "#1AA7B7",
            border: "1px solid #808080"
        },
        {
            background: "#A6F139",
            border: "1px solid #808080"
        },
        {
            background: "#A6F139",
            border: "1px solid #808080"
        },
        {
            background: "#F7FA00",
            border: "1px solid #808080"
        },
        {
            background: "#FF5B6E",
            border: "1px solid #808080"
        },
        {
            background: "#AE77FF",
            border: "1px solid #808080"
        },
        {
            background: "#F2E50B",
            border: "1px solid #808080"
        },
        {
            background: "#17DEEE",
            border: "1px solid #808080"
        },
        {
            background: "#21B20C",
            border: "1px solid #808080"
        },
        {
            background: "#FF7F50",
            border: "1px solid #808080"
        },
        {
            background: "#FF4162",
            border: "1px solid #808080"
        },
        {
            background: "#9B18BC",
            border: "1px solid #808080"
        },
        {
            background: "#E01537",
            border: "1px solid #808080"
        },
        {
            background: "#FF5400",
            border: "1px solid #808080"
        },
        {
            background: "#FFB300",
            border: "1px solid #808080"
        },
        {
            background: "#40BD00",
            border: "1px solid #808080"
        }

    ]

    return (
        <div className='paleta_colores'>            
            {
                estilos.map((item, index) => ( 
                    <div className='recaudro' style={item} key={index} onClick={ObtenerColor} value={item.background}></div>
                ))
            }            
        </div>
    )
}

export default PaletaColores