import React, {useContext} from 'react';
import Context from '../../context/Context';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';

const Mochila = () => {
    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;
    }

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };
    return (
        <div className='div-dibujo'>
            <svg    version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                    x="0px" y="0px"
                    width="459.8px" 
                    height="460.87px" 
                    viewBox="0 0 459.8 460.87" 
                    onClick={obtenetID}
            >
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M362.88,12.94C269.57,38.6,181.28,38.33,98.02,12.14C55.21,93.96-72.26,408.17,85.14,448.98c191.93-0.48,287.9-0.72,287.9-0.72
                    C526.87,395.36,415.94,109.75,362.88,12.94z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M8.85,313.74c2.97-58.15,16.13-119.41,39.46-183.77c244.11,0.55,366.16,0.82,366.16,0.82c25.7,75.91,38.02,136.89,36.94,182.95
                    C262.29,311.71,114.77,311.71,8.85,313.74z"/>
                <g>
                    
                        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                        63.35,168.41 84.33,196.57 105.31,224.74 84.33,252.91 63.35,281.07 42.37,252.91 21.39,224.74 42.37,196.57 	"/>
                    
                        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                        146.75,168.41 167.73,196.57 188.71,224.74 167.73,252.91 146.75,281.07 125.77,252.91 104.79,224.74 125.77,196.57 	"/>
                    
                        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                        230.58,168.41 251.56,196.57 272.54,224.74 251.56,252.91 230.58,281.07 209.6,252.91 188.62,224.74 209.6,196.57 	"/>
                    
                        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                        314.4,168.41 335.38,196.57 356.36,224.74 335.38,252.91 314.4,281.07 293.43,252.91 272.45,224.74 293.43,196.57 	"/>
                    
                        <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                        397.7,168.41 418.68,196.57 439.66,224.74 418.68,252.91 397.7,281.07 376.72,252.91 355.74,224.74 376.72,196.57 	"/>
                </g>
                <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                    52.04,339.53 69.68,361.75 87.32,383.97 69.68,406.19 52.04,428.42 34.4,406.19 16.76,383.97 34.4,361.75 "/>
                <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                    122.14,339.53 139.78,361.75 157.43,383.97 139.78,406.19 122.14,428.42 104.5,406.19 86.86,383.97 104.5,361.75 "/>
                <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                    192.25,339.53 209.89,361.75 227.53,383.97 209.89,406.19 192.25,428.42 174.61,406.19 156.97,383.97 174.61,361.75 "/>
                <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                    262.81,339.53 280.45,361.75 298.09,383.97 280.45,406.19 262.81,428.42 245.17,406.19 227.53,383.97 245.17,361.75 "/>
                <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                    332.91,339.53 350.55,361.75 368.19,383.97 350.55,406.19 332.91,428.42 315.27,406.19 297.63,383.97 315.27,361.75 "/>
                <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" points="
                    403.48,339.53 421.12,361.75 438.76,383.97 421.12,406.19 403.48,428.42 385.84,406.19 368.19,383.97 385.84,361.75 "/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M79.19,275.51c15.56,4.95,27.88,8.69,36.96,11.22C122.86,261.02,87.67,252.68,79.19,275.51z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M116.16,286.72c-24.64-7.48-36.96-11.22-36.96-11.22c-52.08,51.97-68.52,82.62-49.32,91.94c50.4,15.28,75.6,22.92,75.6,22.92
                    C130.42,395,133.98,360.45,116.16,286.72z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M111.32,267.21c-5.21-4-10.38-5.64-15.51-4.91c32.66-97.43,76.52-159.51,131.58-186.22c4.97,9.15,7.46,13.72,7.46,13.72
                    C182.66,116.19,141.48,175.33,111.32,267.21z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M91.61,279.41C66.6,307.19,47.54,337,34.45,368.83"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M105.86,283.6c-5.98,38.63-15.51,71.37-28.58,98.22"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M382.76,275.51c-15.56,4.95-27.89,8.69-36.96,11.22C339.1,261.02,374.29,252.68,382.76,275.51z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M345.8,286.72c24.64-7.48,36.96-11.22,36.96-11.22c52.08,51.97,68.52,82.62,49.32,91.94c-50.4,15.28-75.6,22.92-75.6,22.92
                    C331.54,395,327.98,360.45,345.8,286.72z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M350.64,267.21c5.21-4,10.38-5.64,15.51-4.91c-32.66-97.43-76.52-159.51-131.58-186.22c-4.97,9.15-7.46,13.72-7.46,13.72
                    C279.3,116.19,320.48,175.33,350.64,267.21z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M370.35,279.41c25.01,27.78,44.07,57.59,57.16,89.42"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M356.1,283.6c5.98,38.63,15.51,71.37,28.58,98.22"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M235.79,66.49c-6.2,5.35-10.93,12.34-14.2,20.98c10.77,44.02,88.4,115.14,120.36,77.35C375.93,124.65,273.33,64.88,235.79,66.49z
                    M326.36,155.31c-13.68,6.1-32.43-0.68-56.25-20.33c-18.64-18.77-28.71-34.6-30.21-47.48c-0.05-3.34,1.07-5.27,3.34-5.79
                    C265.27,86.02,350.8,130.66,326.36,155.31z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M116.87,164.82c31.97,37.79,109.59-33.33,120.36-77.35c-3.26-8.63-8-15.63-14.2-20.98C185.5,64.88,82.9,124.65,116.87,164.82z
                    M215.58,81.72c2.27,0.52,3.38,2.45,3.34,5.79c-1.5,12.88-11.57,28.7-30.21,47.48c-23.82,19.65-42.57,26.42-56.25,20.33
                    C108.03,130.66,193.55,86.02,215.58,81.72z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="22.9256" d="
                    M230.73,90.47c7.11,0,12.91-5.8,12.91-12.91c0-7.11-5.8-12.91-12.91-12.91c-7.11,0-12.91,5.8-12.91,12.91
                    C217.82,84.67,223.62,90.47,230.73,90.47z"/>
            </svg>

            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default Mochila