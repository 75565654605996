import {useReducer} from 'react';
import Context from './Context';
import Reducer from './Reducer';

import { COLOR } from '../types/index';


const State = (props) => {
    const initialState = {
        colorObtenido: "#ff0"
    }

    const [state, dispatch] = useReducer(Reducer, initialState);

    const obtenerColor = (value) =>{
        dispatch({
            type: COLOR,
            payload: value
        })
    }

    return(
        <Context.Provider
            value = {{
                colorObtenido: state.colorObtenido,
                obtenerColor
            }}
        >{props.children}
        </Context.Provider>
    )
}

export default State;