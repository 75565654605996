import React, {useContext} from 'react';
import Context from '../../context/Context';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';

const DibujoPrueba = () => {

    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;        
    }

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };

    
    return (
        <div className='div-dibujo'>
        <svg    version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                x="0px" y="0px"
                width="570px" height="477px" 
                viewBox="0 0 570 477" 
                onClick={obtenetID}
        >
            <path id="color_00000021090841701541733270000006505003471585850034_" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="
                M542.15,343.77c-1.1-0.64-2.21-1.27-3.32-1.9c-7.09-7.01-9.74-21.19-14.04-29.72c-5.49-10.89-10.98-21.78-16.47-32.66
                c-5.49-10.89-10.97-21.77-16.46-32.66c-5.65-11.22-12.04-18.03-7.53-30.39c19.33-53.02,41.53-106.64,80.27-147.68
                c-24.28-2.16-48.49-5.13-72.57-8.88c-18.64-2.91-37.22-6.28-55.69-10.13c-28.37-5.9-56.5-12.91-84.33-21
                c-3.86-1.12-7.71-2.26-11.56-3.43c-18.32-5.55-36.5-10.87-54.34-17.64c0.05,0.34,0.08,0.68,0.13,1.01c-0.13-0.13-0.26-0.27-0.39-0.4
                c0.77,0.72-2.12,8.07-2.43,9.02c-1.27,3.87-2.71,7.67-4.32,11.41c-3.29,7.62-7.3,14.92-11.96,21.79
                c-4.33,6.39-9.33,12.49-15.78,16.73c-1.08,0.71-2.18,1.35-3.31,1.95c-12.2,6.4-27.14,6.19-41.01,4.59
                c-7.36-0.85-14.69-2.03-21.97-3.41c-6.51-1.24-13.02-3.17-19.55-4.16c-0.54-0.52-1.09-1.02-1.62-1.55
                c-3.73,9.92-7.86,19.66-12.67,29.1c-5.51,10.2-11.31,20.24-17.36,30.13c-3.96,6.47-8.02,12.87-12.18,19.21
                c-8.58,13.07-17.56,25.88-26.84,38.46c-5.37,7.28-10.84,14.5-16.38,21.64c-23.02,29.66-47.44,58.22-72.07,86.55
                c48.67,21.53,100.43,36.06,153.19,43.02c8.99,1.19,18.29,2.24,26.18,6.69c10.06,5.67,17.86,16.65,26.2,24.55
                c9.42,8.91,19.16,17.48,29.19,25.68c19.69,16.12,40.53,30.84,62.3,44.01c9.62,5.82,19.59,11.46,27.21,19.73
                c0.16-0.52,0.34-1.03,0.5-1.55c1.66,2.05,3.32,4.1,4.99,6.15c2.86,3.5,5.42,7.29,9.11,9.97c0.7,0.51,1.84-0.35,1.52-1.17
                c-1.64-4.15-4.62-7.41-7.46-10.79c-2.24-2.67-4.59-5.23-6.97-7.77c0.3-0.89,0.61-1.78,0.92-2.67c3.39,3.92,6.89,7.83,10.72,11.18
                c0.99,0.87,2.64-0.23,1.9-1.46c-1.69-2.79-3.77-5.42-6.03-7.9c3.81,3.09,8.09,5.42,12.78,5.32c0.97-0.02,1.39-1.37,0.51-1.87
                c-3.49-1.96-7.15-3.38-10.44-5.73c-1.83-1.31-3.59-2.73-5.3-4.2c3.92,2.83,8.01,5.49,12.49,6.72c1.1,0.3,1.85-1.07,1.09-1.87
                c-3.83-4.07-9.25-6.68-14.44-9.17c0.38-0.99,0.77-1.97,1.15-2.95c4.06,3.51,8.16,6.97,12.67,9.83c1.33,0.84,2.69-1.03,1.64-2.13
                c-3.92-4.12-8.42-7.63-12.93-11.13c0.2-0.48,0.4-0.96,0.6-1.44c1.55,1.26,3.1,2.53,4.61,3.83c3.01,2.59,5.97,5.69,9.73,7.15
                c1,0.39,1.57-0.84,1.23-1.6c-0.79-1.81-2.05-3.38-3.49-4.81c0.84,0.5,1.72,0.94,2.66,1.25s1.75-0.83,1.27-1.65
                c-1.99-3.36-5.62-5.54-8.63-7.92c-1.42-1.13-2.87-2.22-4.32-3.3c0.4-0.88,0.81-1.76,1.22-2.63c1.51,1.47,3.07,2.89,4.72,4.23
                c2.85,2.32,5.95,5.06,9.65,5.74c0.8,0.15,1.64-0.68,1.13-1.47c-2.04-3.15-5.57-5.21-8.44-7.53c-0.88-0.71-1.74-1.44-2.62-2.16
                c2.14,1.2,4.28,2.46,6.76,2.64c0.61,0.04,1.05-0.74,0.71-1.24c-1.51-2.26-3.96-3.53-6.24-4.92c-0.88-0.54-1.79-1.03-2.71-1.51
                c0.19-0.39,0.38-0.78,0.57-1.17c0.56-1.13,1.14-2.25,1.72-3.37c3.31,4.31,7.92,7.75,13.21,8.71c1.03,0.18,1.56-1.06,0.7-1.67
                c-4.44-3.17-8.68-6.14-12.41-9.92c0.37-0.69,0.73-1.39,1.11-2.08c1.34,1.41,2.75,2.74,4.27,4.01c2.58,2.16,5.89,5.25,9.37,5.47
                c0.87,0.05,1.44-1.02,0.99-1.73c-1.77-2.84-5.6-4.61-8.13-6.74c-1.62-1.37-3.17-2.83-4.67-4.35c0.24-0.44,0.49-0.88,0.73-1.32
                c4.27,3.53,9.12,7.14,14.14,8.24c1.04,0.23,1.95-1.07,1.09-1.87c-2.43-2.28-5.6-3.73-8.43-5.47c-1.81-1.12-3.56-2.31-5.29-3.53
                c0.38-0.65,0.75-1.3,1.13-1.95c1.87,1.7,3.83,3.27,5.98,4.7c3.03,2.02,6.35,4.41,10.12,4.37c0.97-0.01,1.34-1.13,0.75-1.81
                c-2.21-2.56-5.6-3.76-8.44-5.51c-2.3-1.41-4.54-2.96-6.72-4.58c0.3-0.5,0.6-1,0.91-1.49c1.62,1.53,3.31,2.99,5.09,4.32
                c2.68,2.01,5.79,4.45,9.27,4.5c0.52,0.01,0.9-0.61,0.61-1.06c-1.66-2.63-4.64-4.1-7.11-5.88c-2.1-1.51-4.14-3.06-6.12-4.69
                c0.1-0.17,0.21-0.33,0.32-0.5c3.58,3.34,7.9,7.01,12.38,7.86c1.18,0.22,2.01-1.09,1.18-2.02c-1.76-1.95-4.27-3.26-6.45-4.69
                c-1.85-1.22-3.64-2.51-5.39-3.85c0.47-0.72,0.94-1.43,1.41-2.15c1.83,1.81,3.73,3.53,5.77,5.15c2.12,1.68,5.17,4.77,8.07,3.92
                c0.31-0.09,0.65-0.44,0.61-0.8c-0.37-2.82-3.65-4.38-5.76-5.98c-2.26-1.71-4.45-3.53-6.61-5.39c0.61-0.9,1.22-1.79,1.84-2.68
                c2.04,2.22,4.07,4.45,6.23,6.55c2.13,2.08,4.39,5.09,7.35,5.89c0.79,0.22,1.58-0.54,1.34-1.34c-0.86-2.96-3.87-5.14-6-7.25
                c-2.27-2.25-4.67-4.36-7.06-6.48c0.18-0.24,0.37-0.47,0.56-0.71c1.2,1.3,2.42,2.59,3.68,3.83c2.22,2.19,4.52,5.1,7.68,5.79
                c0.6,0.13,1.12-0.37,0.98-0.98c-0.26-1.11-0.81-2.1-1.49-3.02c0.64,0.45,1.31,0.84,2.04,1.13c0.7,0.27,1.47-0.55,1.23-1.23
                c-0.88-2.5-2.95-4.39-4.74-6.27c-1.41-1.48-2.88-2.9-4.36-4.31c0.32-0.22,0.64-0.43,0.96-0.63c2.34,2.52,4.82,4.91,7.69,6.69
                c1.04,0.65,2.35-0.65,1.69-1.69c-1.57-2.5-3.61-4.7-5.79-6.77c0.45-0.18,0.91-0.35,1.37-0.51c1.11-0.39,2.24-0.73,3.37-1.06
                c1.98,4.28,4.25,8.5,7.48,11.73c0.69,0.69,1.68-0.03,1.53-0.89c-0.74-4.17-2.65-8.19-4.83-12.02c0.66-0.18,1.31-0.37,1.97-0.55
                c2.11,3.52,4.25,6.92,7.04,10.02c0.81,0.9,2.6-0.05,2.12-1.23c-1.43-3.53-3.3-6.68-5.31-9.81c0.93-0.24,1.86-0.49,2.8-0.73
                c2.7,5.2,6.62,10.56,11.61,12.58c1.15,0.47,1.98-1.03,1.5-1.95c-1.54-2.92-4.28-5.22-6.3-7.81c-0.97-1.24-1.88-2.52-2.78-3.82
                c0.94-0.23,1.89-0.47,2.83-0.69c1.32,1.68,2.7,3.31,4.21,4.83c1.94,1.95,4.48,4.34,7.17,5.12c0.83,0.24,1.76-0.79,1.2-1.56
                c-1.61-2.23-4.14-3.9-6.18-5.75c-1.17-1.06-2.28-2.18-3.34-3.34c1.44-0.33,2.88-0.66,4.33-0.97c3.81,5.07,9.37,10.31,15.64,10.72
                c1.2,0.08,1.68-1.48,0.94-2.26c-2.19-2.31-5.26-3.76-7.86-5.56c-1.67-1.15-3.34-2.39-4.98-3.68c1.74-0.36,3.48-0.7,5.23-1.04
                c3.05,4.75,6.72,9.35,11.42,12.29c1.02,0.64,2.19-0.61,1.61-1.61c-2.03-3.52-4.72-6.72-7.54-9.79c0.97,0.77,1.94,1.53,2.95,2.24
                c2.39,1.69,5.08,3.84,8.03,4.26c0.71,0.1,1.6-0.63,1.07-1.39c-1.6-2.24-4.24-3.62-6.51-5.12c-1.15-0.76-2.31-1.51-3.47-2.27
                c1.96-0.34,3.93-0.66,5.89-0.97c2.6,3.71,5.72,7.36,9.52,8.97c0.87,0.37,1.92-0.42,1.37-1.37c-1.41-2.46-3.65-4.41-5.6-6.44
                c-0.54-0.56-1.04-1.16-1.57-1.74c2.29-0.34,4.59-0.66,6.89-0.96c0.24,0.69,0.47,1.39,0.71,2.08c0.83,2.45,1.48,5.06,2.98,7.2
                c0.7,1,2.63,0.77,2.53-0.68c-0.12-1.7-0.54-3.43-1.09-5.17c2.11,3.02,4.5,5.78,7.36,7.66c0.62,0.41,1.56-0.28,1.3-1
                c-1.2-3.28-3.5-6.06-5.56-8.83c-0.51-0.69-1.01-1.38-1.53-2.07c1.22-0.14,2.44-0.27,3.65-0.4c1.53,2.61,3.21,5.06,5.19,7.36
                c1.82,2.11,4.39,5.16,7.22,5.76c0.82,0.17,1.83-0.56,1.49-1.49c-0.93-2.5-3.44-4.47-5.23-6.41c-1.65-1.78-3.2-3.68-4.69-5.62
                c2.43-0.23,4.87-0.44,7.3-0.62c0.95,1.51,1.97,2.96,3.13,4.35c1.58,1.89,3.47,4.23,5.85,5.12c0.41,1.08,0.93,2.11,1.68,3.05
                c0.9,1.14,2.37,0.05,2.52-1.04c0.37-2.75-0.74-5.55-1.48-8.18c-0.28-1-0.6-1.98-0.9-2.97c2.29,4.44,5.19,8.95,8.97,11.76
                c1.01,0.75,2.51-0.5,2.12-1.63c-1.34-3.94-3.85-7.74-6.41-11.35c0.57-0.02,1.13-0.04,1.7-0.06c2.16,4.11,4.57,8.39,8.15,11.03
                c1.02,0.75,2.23-0.49,1.99-1.53c-0.12-0.53-0.28-1.05-0.46-1.57c0.39-0.11,0.73-0.44,0.68-0.86c-0.26-2.55-1.45-4.98-2.9-7.22
                c0.35,0,0.69,0,1.04,0c1.45,3.7,3,7.41,5.24,10.59c0.59,0.84,2.15,0.63,2.09-0.57c-0.03-0.64-0.11-1.26-0.21-1.89
                c0.14,0.41,0.28,0.81,0.42,1.22c1.1,3.16,2.07,6.63,4.37,9.14c0.35,0.38,1.14,0.22,1.23-0.33c0.96-5.82-2.28-12.46-5.32-17.99
                c0.24,0.01,0.48,0.02,0.72,0.02c1.73,4.07,4.23,8.36,7.89,10.69c0.93,1.66,2.02,3.16,3.31,4.4c0.81,0.78,2.55,0.28,2.42-1
                c-0.42-4.04-2.33-7.86-3.6-11.71c-0.23-0.7-0.43-1.4-0.65-2.11c0.46,0.01,0.93,0.01,1.4,0.02c2.68,7.77,7.21,16.06,14.98,17.96
                c0.99,0.24,1.64-0.94,0.98-1.68c-3.28-3.64-6.76-6.89-9.27-11.19c-0.97-1.66-1.84-3.38-2.68-5.11c0.82-0.01,1.63-0.03,2.45-0.06
                c2.89,4.53,6.32,9.07,10.48,12.12c0.77,0.57,2.06-0.39,1.69-1.3c-1.65-3.98-4.41-7.72-7.44-11.11c1.13-0.1,2.25-0.23,3.36-0.38
                c1.39,2.05,2.84,4.06,4.57,5.95c2.82,3.06,6.37,7.21,10.56,8.21c1.02,0.24,1.82-0.82,1.33-1.73c-1.97-3.68-6.27-6.28-9.28-9.09
                c-1.35-1.26-2.77-2.53-4.17-3.82c1.12-0.21,2.22-0.46,3.3-0.74c1.59,1.62,3.26,3.16,5.06,4.6c2.52,2.03,5.34,4.55,8.67,4.81
                c0.54,0.04,1.05-0.6,0.85-1.12c-0.77-2.04-2.38-3.68-4.13-5.15c1.39,0.53,2.79,0.84,4.18,0.85c0.71,0,1.32-0.87,0.87-1.51
                C546.96,346.43,544.44,345.09,542.15,343.77z M451.45,271.1c0.01,0.01,0.01,0.02,0.02,0.03c-0.79-0.55-1.58-1.11-2.37-1.66
                C449.88,270.02,450.67,270.56,451.45,271.1z M341.29,425.05c0.01-0.03,0.03-0.06,0.04-0.09c0.31,0.26,0.62,0.52,0.93,0.78
                C341.94,425.51,341.61,425.27,341.29,425.05z M373.49,369.8c0.04-0.04,0.08-0.09,0.12-0.13c0.42,0.47,0.84,0.94,1.26,1.4
                C374.41,370.65,373.96,370.21,373.49,369.8z M473.54,351.01c-0.74-0.85-1.46-1.73-2.18-2.61c0.72-0.05,1.45-0.09,2.17-0.13
                c0.29,1.25,0.6,2.5,0.92,3.74C474.14,351.67,473.83,351.34,473.54,351.01z M497.11,347.67c0.01,0,0.02,0,0.03,0
                c0.02,0.06,0.04,0.12,0.06,0.18C497.17,347.79,497.14,347.73,497.11,347.67z M505.38,347.93c0.06,0,0.12,0,0.17,0.01
                c0.02,0.11,0.05,0.23,0.08,0.34C505.55,348.16,505.46,348.05,505.38,347.93z"/>
            <path id="color_00000129884294809365037630000006837618627778873498_" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="
                M204.04,269.93l-0.19,0.32c19.54,7.34,39.51,13.55,59.78,18.55c3.24,0.8,6.56,1.6,9.31,3.48c2.81,1.93,4.79,4.82,6.68,7.66
                c13.96,20.85,27.38,42.05,40.26,63.58c-0.69-2.71-1.39-5.42-2.07-8.14c-9.72-38.26-19.98-76.16-28.85-114.46
                c-19.12-8.94-38.24-17.89-57.07-27.42c-5.23-2.65-10.41-5.36-15.53-8.14c-0.83,13.26-2.39,26.48-4.62,39.48
                C210.24,253.51,208.4,262.29,204.04,269.93z"/>
            <path id="color_00000021089387707310765710000008201692382094149540_" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="
                M384.85,373.5c1.04,0.65,2.35-0.65,1.69-1.69c-1.57-2.5-3.61-4.7-5.79-6.77c0.45-0.18,0.91-0.35,1.37-0.51
                c-17.59-30.83-35.17-61.66-52.75-92.48c-0.76-1.35-1.53-2.69-2.33-4.02v-0.01l-0.01-0.01c-2.25-3.77-4.7-7.41-7.85-10.44
                c-4.88-4.71-11.13-7.68-17.27-10.57c-4.32-2.02-8.64-4.05-12.96-6.07c-19.12-8.94-38.24-17.89-57.07-27.42
                c-5.23-2.65-10.41-5.36-15.53-8.14c-5.17-2.8-10.28-5.67-15.33-8.6c-27.39-15.87-53.29-33.6-78.68-53.16
                c-0.22-0.17-0.44-0.34-0.66-0.51c-8.58,13.07-17.56,25.88-26.84,38.46c-5.37,7.28-10.84,14.5-16.38,21.64
                c28.7,20.64,59.45,38.43,91.63,53.07c11.09,5.04,22.35,9.71,33.75,13.98c19.54,7.34,39.51,13.55,59.78,18.55
                c3.24,0.8,6.56,1.6,9.31,3.48c2.81,1.93,4.79,4.82,6.68,7.66c13.96,20.85,27.38,42.05,40.26,63.58
                c8.72,14.57,17.19,29.28,25.4,44.14c0.75,1.34,1.49,2.68,2.22,4.02c0.56-1.13,1.14-2.25,1.72-3.37c3.31,4.31,7.92,7.75,13.21,8.71
                c1.03,0.18,1.56-1.06,0.7-1.67c-4.44-3.17-8.68-6.14-12.41-9.92c0.37-0.69,0.73-1.39,1.11-2.08c1.34,1.41,2.75,2.74,4.27,4.01
                c2.58,2.16,5.89,5.25,9.37,5.47c0.87,0.05,1.44-1.02,0.99-1.73c-1.77-2.84-5.6-4.61-8.13-6.74c-1.62-1.37-3.17-2.83-4.67-4.35
                c0.24-0.44,0.49-0.88,0.73-1.32c4.27,3.53,9.12,7.14,14.14,8.24c1.04,0.23,1.95-1.07,1.09-1.87c-2.43-2.28-5.6-3.73-8.43-5.47
                c-1.81-1.12-3.56-2.31-5.29-3.53c0.38-0.65,0.75-1.3,1.13-1.95c1.87,1.7,3.83,3.27,5.98,4.7c3.03,2.02,6.35,4.41,10.12,4.37
                c0.97-0.01,1.34-1.13,0.75-1.81c-2.21-2.56-5.6-3.76-8.44-5.51c-2.3-1.41-4.54-2.96-6.72-4.58c0.3-0.5,0.6-1,0.91-1.49
                c1.62,1.53,3.31,2.99,5.09,4.32c2.68,2.01,5.79,4.45,9.27,4.5c0.52,0.01,0.9-0.61,0.61-1.06c-1.66-2.63-4.64-4.1-7.11-5.88
                c-2.1-1.51-4.14-3.06-6.12-4.69c0.1-0.17,0.21-0.33,0.32-0.5c3.58,3.34,7.9,7.01,12.38,7.86c1.18,0.22,2.01-1.09,1.18-2.02
                c-1.76-1.95-4.27-3.26-6.45-4.69c-1.85-1.22-3.64-2.51-5.39-3.85c0.47-0.72,0.94-1.43,1.41-2.15c1.83,1.81,3.73,3.53,5.77,5.15
                c2.12,1.68,5.17,4.77,8.07,3.92c0.31-0.09,0.65-0.44,0.61-0.8c-0.37-2.82-3.65-4.38-5.76-5.98c-2.26-1.71-4.45-3.53-6.61-5.39
                c0.61-0.9,1.22-1.79,1.84-2.68c2.04,2.22,4.07,4.45,6.23,6.55c2.13,2.08,4.39,5.09,7.35,5.89c0.79,0.22,1.58-0.54,1.34-1.34
                c-0.86-2.96-3.87-5.14-6-7.25c-2.27-2.25-4.67-4.36-7.06-6.48c0.18-0.24,0.37-0.47,0.56-0.71c1.2,1.3,2.42,2.59,3.68,3.83
                c2.22,2.19,4.52,5.1,7.68,5.79c0.6,0.13,1.12-0.37,0.98-0.98c-0.26-1.11-0.81-2.1-1.49-3.02c0.64,0.45,1.31,0.84,2.04,1.13
                c0.7,0.27,1.47-0.55,1.23-1.23c-0.88-2.5-2.95-4.39-4.74-6.27c-1.41-1.48-2.88-2.9-4.36-4.31c0.32-0.22,0.64-0.43,0.96-0.63
                C379.5,369.33,381.98,371.72,384.85,373.5z M373.49,369.8c0.04-0.04,0.08-0.09,0.12-0.13c0.42,0.47,0.84,0.94,1.26,1.4
                C374.41,370.65,373.96,370.21,373.49,369.8z"/>
            <path id="color_00000056404155570139416650000000925488422947887000_" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="
                M512.72,357.59c-1.25-2.43-3.42-4.38-5.06-6.55c-0.69-0.92-1.37-1.83-2.03-2.75c-0.08-0.12-0.17-0.23-0.25-0.35
                c-0.88-1.24-1.72-2.5-2.48-3.84c-0.29-0.5-0.8-0.69-1.29-0.64c-5.76-8.02-11.49-16.06-17.16-24.14
                c-11.18-15.93-22.17-31.99-32.98-48.18c-0.79-0.55-1.58-1.11-2.37-1.66c0.78,0.54,1.57,1.08,2.35,1.63
                c-10.78-16.14-21.37-32.4-31.78-48.79c-1.8-2.83-3.63-5.74-4.28-9.04c-0.7-3.51-0.01-7.12,0.67-10.64l0.03-0.16
                c1.29-6.64,2.52-13.28,3.7-19.94c3.67-20.67,6.83-41.45,9.5-62.29c2.99-23.43,5.35-46.94,7.05-70.5c-28.37-5.9-56.5-12.91-84.33-21
                c10.06,60.06,11.3,121.92,17.36,182.88c0.67,6.75,1.39,13.5,2.2,20.23c0.42,3.46,0.88,7.01,2.5,10.1c2.02,3.84,5.61,6.57,9.07,9.19
                c11.15,8.44,22.15,17.1,32.96,25.97c24.13,19.77,47.41,40.57,69.76,62.35c2.1,2.05,4.2,4.15,6.27,6.28
                c0.24,0.62,0.49,1.24,0.73,1.87c1.45,3.7,3,7.41,5.24,10.59c0.59,0.84,2.15,0.63,2.09-0.57c-0.03-0.64-0.11-1.26-0.21-1.89
                c0.14,0.41,0.28,0.81,0.42,1.22c1.1,3.16,2.07,6.63,4.37,9.14c0.35,0.38,1.14,0.22,1.23-0.33c0.96-5.82-2.28-12.46-5.32-17.99
                c0.24,0.01,0.48,0.02,0.72,0.02c1.73,4.07,4.23,8.36,7.89,10.69c0.62,0.39,1.27,0.73,1.95,1
                C512.38,359.94,513.18,358.49,512.72,357.59z M497.11,347.67c0.01,0,0.02,0,0.03,0c0.02,0.06,0.04,0.12,0.06,0.18
                C497.17,347.79,497.14,347.73,497.11,347.67z"/>
            <path id="color_00000092435512508052918550000007086123748332551078_" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="
                M212.09,99.31c18.75,9.28,38.55,16.45,58.91,21.27c2.05,0.48,4.29,0.93,6.2,0.04c2.62-1.21,3.59-4.38,4.23-7.2
                c7.79-34.25,9.42-69.9,4.81-104.73c-0.13-0.13-0.26-0.27-0.39-0.4c0.77,0.72-2.12,8.07-2.43,9.02c-1.27,3.87-2.71,7.67-4.32,11.41
                c-3.29,7.62-7.3,14.92-11.96,21.79c-4.33,6.39-9.33,12.49-15.78,16.73c-1.08,0.71-2.18,1.35-3.31,1.95
                c-12.2,6.4-27.14,6.19-41.01,4.59c-7.36-0.85-14.69-2.03-21.97-3.41c-6.51-1.24-13.02-3.17-19.55-4.16
                c12.53,12.1,26.39,22.69,41.88,30.73C208.96,97.74,210.52,98.53,212.09,99.31z"/>
            <path id="color_00000128475887813167707220000002024149269684974224_" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="
                M542.15,343.77c-1.1-0.64-2.21-1.27-3.32-1.9c-7.09-7.01-9.74-21.19-14.04-29.72c-5.49-10.89-10.98-21.78-16.47-32.66
                c-5.49-10.89-10.97-21.77-16.46-32.66c-5.65-11.22-12.04-18.03-7.53-30.39c19.33-53.02,41.53-106.64,80.27-147.68
                c-24.28-2.16-48.49-5.13-72.57-8.88c-18.64-2.91-37.22-6.28-55.69-10.13c-1.7,23.56-4.06,47.07-7.05,70.5
                c-2.67,20.83-5.83,41.61-9.5,62.29c-1.18,6.66-2.41,13.3-3.7,19.94l-0.03,0.16c-0.68,3.51-1.38,7.13-0.67,10.64
                c0.65,3.29,2.48,6.21,4.28,9.04c10.41,16.39,21,32.65,31.78,48.79c0.01,0.01,0.02,0.02,0.02,0.03
                c10.81,16.19,21.8,32.24,32.98,48.18c5.67,8.08,11.4,16.12,17.16,24.14c0.49-0.05,1,0.14,1.29,0.64c0.76,1.34,1.6,2.6,2.48,3.84
                c0.06,0,0.12,0,0.17,0.01c0.02,0.11,0.05,0.23,0.08,0.34c0.66,0.92,1.34,1.84,2.03,2.75c1.63,2.18,3.8,4.12,5.06,6.55
                c0.47,0.91-0.33,2.35-1.47,1.91c-0.68-0.27-1.33-0.61-1.95-1c0.93,1.66,2.02,3.16,3.31,4.4c0.81,0.78,2.55,0.28,2.42-1
                c-0.42-4.04-2.33-7.86-3.6-11.71c-0.23-0.7-0.43-1.4-0.65-2.11c0.46,0.01,0.93,0.01,1.4,0.02c2.68,7.77,7.21,16.06,14.98,17.96
                c0.99,0.24,1.64-0.94,0.98-1.68c-3.28-3.64-6.76-6.89-9.27-11.19c-0.97-1.66-1.84-3.38-2.68-5.11c0.82-0.01,1.63-0.03,2.45-0.06
                c2.89,4.53,6.32,9.07,10.48,12.12c0.77,0.57,2.06-0.39,1.69-1.3c-1.65-3.98-4.41-7.72-7.44-11.11c1.13-0.1,2.25-0.23,3.36-0.38
                c1.39,2.05,2.84,4.06,4.57,5.95c2.82,3.06,6.37,7.21,10.56,8.21c1.02,0.24,1.82-0.82,1.33-1.73c-1.97-3.68-6.27-6.28-9.28-9.09
                c-1.35-1.26-2.77-2.53-4.17-3.82c1.12-0.21,2.22-0.46,3.3-0.74c1.59,1.62,3.26,3.16,5.06,4.6c2.52,2.03,5.34,4.55,8.67,4.81
                c0.54,0.04,1.05-0.6,0.85-1.12c-0.77-2.04-2.38-3.68-4.13-5.15c1.39,0.53,2.79,0.84,4.18,0.85c0.71,0,1.32-0.87,0.87-1.51
                C546.96,346.43,544.44,345.09,542.15,343.77z"/>
            <path id="color" fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M359.46,427.59c-2.04-3.15-5.57-5.21-8.44-7.53
                c-0.88-0.71-1.74-1.44-2.62-2.16c2.14,1.2,4.28,2.46,6.76,2.64c0.61,0.04,1.05-0.74,0.71-1.24c-1.51-2.26-3.96-3.53-6.24-4.92
                c-0.88-0.54-1.79-1.03-2.71-1.51c0.19-0.39,0.38-0.78,0.57-1.17c-0.74-1.34-1.47-2.69-2.22-4.02c-8.21-14.86-16.68-29.58-25.4-44.14
                c-12.88-21.53-26.3-42.73-40.26-63.58c-1.89-2.84-3.87-5.73-6.68-7.66c-2.75-1.88-6.07-2.68-9.31-3.48
                c-20.27-5-40.23-11.21-59.78-18.55c-11.4-4.27-22.66-8.94-33.75-13.98c-32.18-14.64-62.93-32.43-91.63-53.07
                c-23.02,29.66-47.44,58.22-72.07,86.55c48.67,21.53,100.43,36.06,153.19,43.02c8.99,1.19,18.29,2.24,26.18,6.69
                c10.06,5.67,17.86,16.65,26.2,24.55c9.42,8.91,19.16,17.48,29.19,25.68c19.69,16.12,40.53,30.84,62.3,44.01
                c9.62,5.82,19.59,11.46,27.21,19.73c0.16-0.52,0.34-1.03,0.5-1.55c1.66,2.05,3.32,4.1,4.99,6.15c2.86,3.5,5.42,7.29,9.11,9.97
                c0.7,0.51,1.84-0.35,1.52-1.17c-1.64-4.15-4.62-7.41-7.46-10.79c-2.24-2.67-4.59-5.23-6.97-7.77c0.3-0.89,0.61-1.78,0.92-2.67
                c3.39,3.92,6.89,7.83,10.72,11.18c0.99,0.87,2.64-0.23,1.9-1.46c-1.69-2.79-3.77-5.42-6.03-7.9c3.81,3.09,8.09,5.42,12.78,5.32
                c0.97-0.02,1.39-1.37,0.51-1.87c-3.49-1.96-7.15-3.38-10.43-5.73c-1.83-1.31-3.59-2.73-5.3-4.2c3.92,2.83,8.01,5.49,12.49,6.72
                c1.1,0.3,1.85-1.07,1.09-1.87c-3.83-4.07-9.25-6.68-14.44-9.17c0.38-0.99,0.77-1.97,1.16-2.95c4.06,3.51,8.16,6.97,12.67,9.83
                c1.33,0.84,2.69-1.03,1.64-2.13c-3.92-4.12-8.42-7.63-12.93-11.13c0.2-0.48,0.4-0.96,0.6-1.44c1.55,1.26,3.1,2.53,4.61,3.83
                c3.01,2.59,5.97,5.69,9.73,7.15c1,0.39,1.57-0.84,1.23-1.6c-0.79-1.81-2.05-3.38-3.49-4.81c0.84,0.5,1.72,0.93,2.66,1.25
                c0.94,0.31,1.75-0.83,1.27-1.65c-1.99-3.36-5.62-5.54-8.63-7.92c-1.42-1.13-2.87-2.22-4.32-3.3c0.4-0.88,0.81-1.76,1.22-2.63
                c1.51,1.47,3.07,2.89,4.72,4.23c2.85,2.32,5.95,5.06,9.65,5.74C359.13,429.21,359.97,428.38,359.46,427.59z M341.29,425.05
                c0.01-0.03,0.03-0.06,0.04-0.09c0.31,0.26,0.62,0.52,0.93,0.78C341.94,425.51,341.61,425.27,341.29,425.05z"/>
            </svg>             

            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default DibujoPrueba