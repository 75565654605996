import React from 'react';
import { Link } from 'react-router-dom';
import poncho from '../../images/poncho.svg';
import carriel from '../../images/carriel.svg';
import sombrero_voltiao from '../../images/sombrero_voltiao.svg';
import mochila_final from '../../images/mochila_final.svg';

import './Opciones.scss';


const Opciones = () => {
    return (
        <div className='div-opciones'>
            <div className='opciones'>                
                <Link to="/">
                    <img src={poncho} alt="imagen"/>
                </Link>
            </div>
            <div className='opciones'>                
                <Link to="/carriel">
                    <img src={carriel} alt="imagen"/>
                </Link>
            </div>
            <div className='opciones'>                
                <Link to="/sombrero">
                    <img src={sombrero_voltiao} alt="imagen"/>  
                </Link>
            </div>
            <div className='opciones'>                
                <Link to="/mochila">
                    <img src={mochila_final} alt="imagen"/>
                </Link>
            </div>
        </div>
    )
}

export default Opciones