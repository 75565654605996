import React, {useContext} from 'react';
import Context from '../../context/Context';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';

const Sombrero = () => {
    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;
    }

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };

    return (
        <div className='div-dibujo'>
            <svg    version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                    x="0px" y="0px"
                    width="583.13px" height="413.47px" 
                    viewBox="0 0 583.13 413.47" 
                    onClick={obtenetID}
            >
                <g>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M133.07,100.09
                        c5.02-1.38,10.94-1.81,16.81-2.47c0.87-0.1,1.34-0.37,1.75-1.09c8.83-15.34,21.49-26.84,36.02-36.63
                        c12.5-8.42,25.42-15.96,39.4-21.67c7.3-2.99,14.8-5.4,22.23-8.02c7.74-2.72,15.77-4.27,23.72-6.12
                        c10.61-2.47,21.41-3.89,32.31-4.39c10-0.46,19.96-0.62,29.9,0.79c7.6,1.09,14.96,2.99,21.6,7.01c2.71,1.63,4.93,3.86,6.93,6.29
                        c5.17,6.24,9.98,12.75,14.43,19.51c0.43,0.65,0.72,1.32,1.85,1.13c13.5-2.24,27.1-2.08,40.72-1.55c15.35,0.6,30.52,2.77,45.49,6.04
                        c22.47,4.89,45.24,7.64,67.98,10.69c3.06,0.41,5.46,0.1,7.99-2c4.67-3.87,10.31-6.09,16.11-7.73c3.58-1,7.24-1.24,10.75,0.6
                        c3.33,1.75,4.27,3.93,3.47,7.57c-1.63,7.48-5.11,14.04-9.78,20.04c-6.88,8.82-13.56,17.8-20.67,26.43
                        c-12.87,15.62-25.04,31.81-38.72,46.76c-10.28,11.22-19.82,23.02-29.68,34.58c-10.91,12.78-21.27,25.94-29.75,40.46
                        c-9.92,17-21.26,33.02-33.3,48.55c-11.01,14.18-23.23,27.34-36.57,39.32c-11.34,10.17-22.7,20.43-35.33,29
                        c-15.44,10.47-31.06,20.76-48.36,28.1c-12.47,5.3-25.06,10.25-38.1,14.03c-6.61,1.91-13.25,3.7-19.92,5.39
                        c-12.79,3.24-25.88,4.37-39.03,5.09c-12.96,0.71-25.91,1.22-38.88,1.31c-4.25,0.03-8.52-0.28-12.78-0.13
                        c-12.75,0.47-25.26-1.46-37.72-3.74c-12.9-2.37-24.61-7.8-35.88-14.34c-9.3-5.4-17.96-11.63-25.57-19.27
                        c-10.48-10.53-18.05-22.86-23.58-36.6c-3.4-8.42-6.64-16.9-8.33-25.82c-1.59-8.35-3.19-16.72-3.53-25.28
                        c-0.62-15.8-1.02-31.61,0.72-47.36c2.4-21.74,9.19-42.05,20.24-61.04c10.84-18.64,24.69-34.42,42.16-46.93
                        c12.12-8.67,25.23-15.55,39.47-20.15C117.14,104.03,124.72,102.08,133.07,100.09z"/>
                </g>
                <g>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M232.92,397.86
                        c-12.93,2.78-24.78,3.7-36.69,4.37c-12.69,0.71-25.38,1.21-38.07,1.3c-4.33,0.03-8.67-0.26-12.98-0.1
                        c-12.47,0.46-24.7-1.44-36.91-3.61c-13.26-2.36-25.31-7.89-36.86-14.6c-9.23-5.37-17.83-11.57-25.38-19.18
                        c-10.47-10.53-18.05-22.86-23.57-36.6c-2.87-7.15-5.61-14.34-7.43-21.83c-1.24-5.09-2.22-10.26-2.94-15.46
                        c-2.78-20.07-2.72-40.25-0.81-60.34c2.11-22.05,9.08-42.62,20.3-61.85c10.82-18.56,24.6-34.32,42.02-46.8
                        c12.16-8.72,25.32-15.65,39.63-20.27c9.67-3.12,19.49-5.7,29.47-7.55c3.53-0.65,7.14-0.93,10.73-1.3c0.77-0.07,1.25-0.25,1.63-0.93
                        c9.11-15.93,22.44-27.6,37.48-37.72c14.75-9.91,30.27-18.2,46.99-24.19c9.27-3.33,18.58-6.61,28.22-8.77
                        c8.97-2,17.93-4.21,27.09-5.17c15.37-1.59,30.75-2.53,46.12,0.01c4.9,0.81,9.67,2.12,14.31,4.03c5.48,2.25,9.69,5.92,13.31,10.48
                        c4.61,5.82,9.04,11.75,13.12,17.95c0.43,0.66,0.75,1.3,1.87,1.1c13.5-2.24,27.1-2.08,40.72-1.53c15.35,0.6,30.52,2.78,45.49,6.04
                        c22.54,4.89,45.37,7.68,68.19,10.72c2.96,0.4,5.21,0.06,7.61-1.93c4.74-3.89,10.41-6.18,16.28-7.83c3.58-1,7.24-1.24,10.75,0.63
                        c3.31,1.77,4.25,3.93,3.44,7.58c-1.53,6.83-4.43,13.01-8.7,18.55c-7.66,9.94-15.09,20.07-23.07,29.72
                        c-16.36,19.8-32.23,40.01-49.76,58.83c-5.65,6.08-10.58,12.82-16.03,19.09c-6.12,7.05-11.94,14.34-17.56,21.79
                        c-6.58,8.73-12.35,17.99-17.86,27.41c-6.05,10.32-13.15,19.9-20.07,29.63c-13.25,18.62-28.47,35.55-45.28,50.92
                        c-11.23,10.28-22.7,20.43-35.3,29.02c-14.91,10.17-30.03,20.12-46.65,27.4c-11.47,5.02-23.1,9.66-35.08,13.37
                        C251.11,393.23,241.42,395.75,232.92,397.86z M341.26,46.66c1.93-1.99,3.44-4.24,4.43-6.85c-1.44,2.31-3.22,4.39-4.67,6.68
                        c-1.59,1.71-3.18,3.42-4.77,5.12c0.13,0.12,0.25,0.22,0.38,0.34C338.27,50.25,340,48.66,341.26,46.66z M373.66,80.6
                        c-0.77,1.18,0.37,2.89-1.27,4.23c-0.04-8.26-0.37-16.16-1.63-24.01c-0.46,2.64,1.16,5.24-0.03,7.89c-0.13-2.13-0.35-4.24-1.18-6.58
                        c-4.09,5.31-8.74,9.66-13.4,14.02c4.8,7.8,8.54,15.96,12.9,24.06c6.49-5.96,12.26-12.23,16.92-19.48c0.34-0.53,0.26-0.99-0.04-1.52
                        c-4.05-6.7-7.86-13.53-12.84-19.62c-0.25-0.29-0.44-0.94-1.18-0.53c0.88,3.7,0.99,7.49,1.18,11.28c0.16,3.44,0.35,6.89,0.54,10.33
                        L373.66,80.6z M155.34,167.37c-5.8,5.14-18.39,19.95-23.23,27.21c-2.43,3.64-4.86,7.27-6.42,11.38
                        c-8.05,21.16-7.71,42.31,1.35,62.91c7.55,17.15,19.57,30.67,36.51,39.41c10.53,5.43,21.92,7.32,33.48,8.57
                        c25.07,2.71,49.8,0.37,74.3-5.18c8.45-1.91,16.93-3.7,25.01-6.89c27-10.63,51.83-25.19,75.04-42.46
                        c24.73-18.42,48.1-38.48,70.22-60.01c0.59-0.57,1.06-1.24,1.44-1.97c10.61-20.48,21.55-40.75,35.13-59.48
                        c0.52-0.71,0.63-1.4,0.59-2.28c-0.25-4.7-2.28-8.64-5.08-12.26c-4.45-5.76-10.75-8.67-17.27-11.22
                        c-13.21-5.15-27.09-6.49-41.09-7.1c-0.82-0.03-1.75-0.41-2.5,0.29c2.09,5.26,4.2,10.48,6.26,15.71c0.4,1,1.06,1.38,2.12,1.56
                        c6.62,1.03,13.01,2.84,19.06,5.86c8.74,4.37,14.75,12.2,13.4,22.98c-0.62,4.96-2.08,9.57-4.25,14
                        c-4.89,9.97-11.11,19.09-17.81,27.88c-13.54,17.77-29.93,32.73-47.96,45.67c-27.35,19.65-56.24,36.64-88.36,47.58
                        c-12.56,4.27-25.39,7.15-38.54,7.83c-25.13,1.31-49.57-2.38-72.96-12.03c-6.32-2.61-12.59-5.34-17.98-9.58
                        c-7.76-6.09-13.5-13.65-16.68-23.19c-3.36-10.08-4.14-20.08-0.87-30.27c2.9-9.05,7.05-17.45,13.37-24.63
                        c0.96-1.09,1.31-2.06,0.84-3.46c-0.69-2.06-1.22-4.2-1.77-6.3C158.9,181.15,157.15,174.37,155.34,167.37z M420.98,128.55
                        c1,3.19,1.99,6.23,2.89,9.27c1.94,6.48,3.11,13.1,3.52,19.86c0.38,6.29-2.44,11.63-4.84,17.08c-4.08,9.27-10.84,16.65-17.62,23.92
                        c-12.68,13.62-27.96,23.89-43.9,33.23c-11.01,6.46-22.49,12-34.35,16.77c-5.73,2.31-11.59,4.28-17.43,6.29
                        c-6.23,2.15-12.44,4.4-18.84,5.99c-12.97,3.21-26.1,5.42-39.48,6.17c-13.34,0.75-26.59,0.43-39.73-2
                        c-10.79-1.99-21.2-5.09-30.33-11.47c-9.08-6.35-15.16-16.03-10.13-28.32c0.25-0.6,0.18-1.13-0.03-1.75
                        c-2.24-6.86-4.59-13.68-6.04-20.77c-0.24-1.18-0.44-2.37-1.32-3.49c-3.21,3.58-5.71,7.52-7.91,11.69
                        c-6.95,13.16-9.3,26.62-4.02,41.12c3.97,10.94,10.73,19.4,20.89,24.6c32.46,16.61,66.72,22.07,102.7,15.25
                        c16.18-3.06,31.37-9.1,46.09-16.31c28.18-13.81,54.78-30.05,78.53-50.78c17.72-15.47,32.95-32.92,44.62-53.4
                        c3.47-6.09,6.42-12.41,6.86-19.51c0.35-5.67-1.1-10.84-6.15-14.29C437.75,132.78,429.56,130.35,420.98,128.55z M148.8,110.31
                        c-0.63,0.13-1.28,0.28-1.91,0.41c-4.95,1-9.75,2.58-14.57,4c-11.13,3.28-21.6,8.01-31.12,14.6c-14.75,10.22-27.38,22.66-38.16,37
                        c-9.5,12.65-17.9,25.94-23.02,41.04c-4.59,13.51-6.49,27.5-7.27,41.72c-0.65,11.92,0.99,23.57,4.43,34.88
                        c3.53,11.6,8.77,22.55,13.74,33.61c10.95,24.36,41.74,50.22,62.41,55.15c13.29,3.17,26.29,7.85,39.97,9.2
                        c15.81,1.56,31.68,2.11,47.61,1.19c15.12-0.87,30.15-2.49,45.06-4.99c12.98-2.16,25.79-5.26,38.39-9.11
                        c14.13-4.33,28.04-9.33,41.62-15.22c6.74-2.93,13.74-5.59,19.71-9.85c10.04-7.15,19.39-15.25,28.13-23.97
                        c10.81-10.76,19.36-23.23,26.51-36.67c1.38-2.59,2.72-5.2,4.08-7.8c-0.19-0.12-0.4-0.22-0.59-0.34c-0.44,0.84-0.81,1.74-1.32,2.52
                        c-13.23,19.82-30.12,36.05-49.44,49.79c-35.7,25.38-75.43,40.5-118.79,46.34c-17.15,2.31-34.35,2.83-51.6,1.4
                        c-27.5-2.28-53.78-8.97-77.88-22.77c-27.51-15.78-47.43-37.94-56.4-68.91c-5.14-17.77-5.06-35.67-0.69-53.56
                        c5.86-23.94,17.59-44.83,33.45-63.49c18.25-21.48,40.44-38.03,65.1-51.41C148.19,114.01,149.33,112.84,148.8,110.31z
                        M412.94,256.26c-0.82,0.77-1.71,1.49-2.47,2.33c-3.86,4.24-8.01,8.17-12.1,12.19c-14.62,14.32-30,27.77-45.81,40.75
                        c-24.84,20.4-53.03,33.98-84.19,41.38c-13.4,3.18-26.84,6.2-40.51,8.07c-11.7,1.6-23.44,2.41-35.23,2.21
                        c-33.27-0.57-62.6-12.44-89.43-31.36c-4.83-3.4-9.04-7.46-12.43-12.32c-15.56-22.32-24.84-46.87-26.12-74.18
                        c-0.46-9.8-0.46-19.67,2.24-29.24c7.89-28.03,23.88-50.6,46.77-68.32c10.47-8.11,21.69-15.12,33.64-20.88
                        c0.62-0.29,1.53-0.4,1.46-1.46c-0.18-2.47-0.32-4.96-0.49-7.57c-0.56,0.25-0.94,0.38-1.3,0.57
                        c-25.65,14.18-48.05,32.26-66.37,55.22c-13.43,16.84-23.63,35.35-28.57,56.49c-4.48,19.15-4.62,38.28,1.28,57.16
                        c5.4,17.28,15.15,31.92,28.38,44.24c17.5,16.3,38.2,26.79,61.02,33.29c33.96,9.67,68.35,9.82,102.88,3.77
                        c11.62-2.03,23.07-4.87,34.32-8.51c15.15-4.89,29.8-10.92,43.84-18.4c25.28-13.46,47.7-30.53,66.48-52.28
                        c6.8-7.86,12.47-16.55,18.64-24.86C410.75,262.05,412.01,259.3,412.94,256.26z M421.21,240.02c-0.81,0.72-1.22,1.05-1.6,1.43
                        c-7.85,7.67-15.77,15.25-24.1,22.39c-10.97,9.41-21.67,19.14-33.71,27.16c-10.03,6.68-19.61,14.07-30.49,19.42
                        c-18.3,8.98-36.95,17.11-56.44,23.23c-10.14,3.18-20.6,4.68-31.08,5.86c-8.99,1.02-18.08,0.74-27.12,1.1
                        c-10.35,0.41-20.54-0.54-30.67-2.37c-11.87-2.13-22.73-7.07-33.55-12.16c-10.54-4.96-20.54-10.73-29.34-18.45
                        c-16.39-14.34-25.63-32.39-28.35-53.91c-0.54-4.36,0.01-8.73-0.32-13.06c-0.54-6.95,0.32-13.72,1.71-20.49
                        c2.71-13.29,9.05-24.92,16.52-36.02c9.75-14.5,21.86-26.6,36.79-35.8c2.56-1.59,2.94-2.75,1.44-5.77
                        c-0.43,0.19-0.85,0.37-1.27,0.59c-8.94,4.7-17.47,10.04-25.51,16.18c-29.13,22.21-42.56,52.2-43.19,88.29
                        c-0.22,12.88,2.56,25.32,7.98,37.07c9.63,20.85,25.16,36.26,44.74,47.71c11.09,6.49,23.3,10.29,35.8,13.03
                        c27.1,5.92,54.35,5.84,81.6,1.12c13.38-2.31,26.45-5.84,39.26-10.41c21.1-7.51,40.88-17.56,59.8-29.49
                        c17.45-11,32.03-25.31,46.51-39.78c6.83-6.85,13.19-14.15,20.58-20.45C418.99,244.87,420.38,242.9,421.21,240.02z M435.57,214.1
                        c-0.19,0.18-0.38,0.35-0.57,0.54c-13.15,13.16-26.41,26.2-40.46,38.44c-8.83,7.68-17.27,15.83-26.81,22.7
                        c-10.41,7.49-21.33,14.1-32.37,20.61c-24.67,14.53-50.94,24.6-79.07,30.06c-14.21,2.75-28.49,3.67-42.85,3.92
                        c-9.63,0.18-19.09-1.65-28.59-3.19c-14.04-2.27-26.62-7.79-37.86-16.41c-7.51-5.76-14.06-12.51-19.8-20.01
                        c-8.08-10.57-13.96-22.26-16.71-35.36c-1.55-7.36-1.91-14.84-2.02-22.36c-0.25-19.67,5.73-37.1,17.98-52.48
                        c7.48-9.39,15.87-17.8,25.31-25.19c0.6-0.47,1.59-0.77,1.41-1.81c-0.24-1.37-0.57-2.71-0.91-4.33c-2.46,1.94-4.7,3.71-6.92,5.48
                        c-11.57,9.25-22.1,19.42-30.15,32C104.93,202.72,98,219.71,97.65,239.1c-0.18,9.94,0.62,19.61,3.87,28.99
                        c5.61,16.19,15.08,29.68,28.6,40.26c9.1,7.11,19.33,12.25,29.68,17.19c9.75,4.67,19.77,8.69,30.47,10.44
                        c19.68,3.22,39.42,2.9,59.14,0.07c10.28-1.47,20.43-3.55,30.21-7.1c14.99-5.45,29.71-11.59,44.08-18.49
                        c18.25-8.77,34.91-20.2,50.86-32.55c17.25-13.35,33.42-28,49.14-43.12c0.38-0.37,0.77-0.79,1.02-1.27
                        C428.03,226.91,431.87,220.55,435.57,214.1z M166.69,121.96c1.47,2.49,2.99,4.31,6.17,4.67c12.5,1.37,24.86,0.15,37.13-1.96
                        c13.78-2.37,27.25-6.07,40.53-10.44c11.29-3.72,22.38-8.04,33.21-12.96c17.93-8.14,35.21-17.4,51.19-29
                        c4-2.91,7.91-5.93,11.64-9.45c-3.06-0.15-3.05-0.15-3.11-3.03c-0.01-0.72,0.12-1.5-0.56-2.11c-1.27,0.04-2.55,0.4-3.81-0.03
                        c-0.78-1.38-0.04-2.93-0.5-4.5c-3.71,3.61-7.49,6.9-11.54,9.91c-29.25,21.66-62.15,35.29-97.27,44.02
                        c-8.92,2.22-17.99,3.77-27.16,4.68c-9.29,0.93-18.58,1.43-27.79-0.34c-3.11-0.59-5.34,0.15-7.55,2.22
                        c0.84,1.06,1.59,2.19,2.52,3.15c1.21,1.22,0.87,2.09-0.35,2.97C168.5,120.43,167.65,121.18,166.69,121.96z M148.04,102.52
                        c-0.43-0.56-0.9-0.52-1.38-0.52c-5.01,0-9.73,1.02-14.07,3.59c-0.71,0.41-1.4,0.94-2.27,0.81c-5.89-0.96-10.82,1.27-15.46,4.53
                        c-3.64,2.56-7.26,5.05-11.85,5.61c-1.18,0.15-2.31,0.81-3.42,1.32c-4.95,2.25-8.89,5.79-12.35,9.88c-1.37,1.6-2.89,2.78-5.03,3.19
                        c-3.56,0.69-6.32,2.84-8.69,5.43c-1.74,1.91-3.33,3.97-4.93,6.01c-0.84,1.05-1.83,1.81-3.09,2.24c-5.45,1.9-9.39,5.46-11.6,10.81
                        c-1.1,2.66-2.64,4.92-5.02,6.55c-3.31,2.27-5.06,5.48-5.51,9.39c-0.24,2.03-1.09,3.62-2.59,4.93c-2.28,1.99-3.49,4.5-3.68,7.51
                        c-0.12,1.77-0.75,3.25-2.02,4.55c-2.94,3-4.37,6.71-4.67,10.85c-0.1,1.37-0.52,2.49-1.49,3.46c-3.5,3.55-4.78,7.91-4.2,12.75
                        c0.24,1.97-0.12,3.58-1.18,5.2c-2.37,3.61-3.34,7.61-2.72,11.9c0.37,2.52,0.07,4.84-0.93,7.17c-1.84,4.28-2.19,8.7-0.63,13.16
                        c0.5,1.43,0.44,2.78-0.16,4.17c-1.65,3.86-1.46,7.71,0.07,11.54c0.53,1.31,0.69,2.65,0.32,4c-1.24,4.43-0.52,8.54,2.24,12.2
                        c0.99,1.31,1.09,2.59,0.91,4.15c-0.47,3.92-0.06,7.67,3,10.6c1.13,1.09,1.49,2.28,1.44,3.83c-0.15,4.58,0.97,8.79,4.21,12.23
                        c0.65,0.68,0.91,1.47,0.94,2.4c0.09,4.11,1.69,7.52,4.71,10.29c1.44,1.34,2.27,2.97,2.69,4.92c1.13,5.18,3.8,9.44,8.04,12.68
                        c1,0.75,1.83,1.62,2.33,2.8c1.83,4.36,4.92,7.49,9.26,9.33c1.59,0.68,2.66,1.74,3.42,3.31c1.68,3.55,4.49,5.79,8.33,6.64
                        c2.16,0.49,3.8,1.53,5.14,3.37c2.59,3.53,5.92,5.84,10.6,5.27c1.09-0.13,1.81,0.31,2.41,1.16c2.5,3.47,6.04,4.86,10.17,4.45
                        c2-0.19,3.5,0.29,5.11,1.38c3.58,2.43,7.38,4.2,11.9,2.74c1.27-0.41,2.22,0.31,3.14,1.06c2.49,2.05,5.36,3.11,8.55,2.58
                        c2.44-0.41,4.52,0.13,6.64,1.3c3.05,1.68,6.3,2.18,9.67,1.02c1.24-0.43,2.36-0.32,3.53,0.25c2.97,1.44,6.09,2,9.33,1.18
                        c2.59-0.66,5.14-0.56,7.64,0.34c4.31,1.55,8.61,1.28,12.82-0.25c4.49-1.62,9.05-1.88,13.69-1c3.9,0.75,7.73,0.68,11.51-0.53
                        c1.68-0.53,3.34-0.54,5.03-0.07c3.17,0.88,6.36,0.88,9.48-0.16c3.3-1.1,6.64-1.46,10.07-1.27c4.15,0.24,8.16-0.18,11.84-2.37
                        c1.12-0.68,2.46-0.81,3.72-1.03c2.94-0.5,5.96-0.57,8.76-1.85c3.21-1.46,6.32-3.39,9.88-3.75c3.7-0.38,7.15-1.68,10.75-2.4
                        c4.3-0.85,8.11-3.08,12.12-4.76c5.33-2.24,10.97-3.58,16.33-5.7c1.62-0.63,3.46-0.97,4.87-2.41c-1.32-0.31-2.12,0.54-3.05,0.88
                        c-4.83,1.83-9.76,3.31-14.66,4.93c-6.83,2.25-13.46,5.12-20.51,6.74c-3.14,0.72-6.39,0.71-9.42,1.8
                        c-5.01,1.78-9.94,3.72-15.34,4.05c-2.02,0.12-4.03,0.68-5.87,1.63c-2.13,1.12-4.43,1.3-6.76,1.21c-5.05-0.16-10.07-0.09-14.97,1.46
                        c-2.12,0.68-4.34,0.4-6.46-0.19c-2.08-0.57-4.14-0.47-6.01,0.37c-3.89,1.75-7.77,1.56-11.79,0.66c-5.08-1.13-10.13-1.1-15.09,0.75
                        c-3.02,1.12-6.12,1.24-9.25,0.32c-1.78-0.53-3.56-0.77-5.45-0.47c-4.46,0.69-8.82,0.35-13-1.52c-1.21-0.54-2.55-0.43-3.8,0.06
                        c-3.09,1.18-6.04,0.74-8.83-0.88c-2-1.16-4.09-1.81-6.42-1.66c-2.9,0.18-5.54-0.56-7.8-2.46c-1.32-1.1-2.97-1.74-4.58-1.28
                        c-4.4,1.27-7.86-0.6-11.23-2.96c-1.69-1.18-3.47-1.68-5.56-1.56c-3.11,0.18-5.95-0.52-8.02-3.18c-1.31-1.69-3.14-2.33-5.26-2.16
                        c-3.18,0.25-5.58-1.16-7.24-3.74c-1.71-2.64-4.08-4.12-7.07-4.8c-3.09-0.69-5.46-2.43-7.1-5.12c-1.07-1.75-2.55-2.99-4.37-3.87
                        c-3.47-1.69-5.92-4.4-7.29-7.96c-0.82-2.16-2.21-3.67-4.03-4.98c-3.14-2.24-5.45-5.14-6.4-8.95c-0.82-3.31-2.21-6.24-4.8-8.58
                        c-1.88-1.72-2.8-3.96-2.69-6.6c0.06-1.65-0.15-3.33-1.38-4.58c-3.18-3.22-4.46-7.1-4.08-11.56c0.13-1.53-0.28-2.89-1.38-3.92
                        c-2.77-2.59-3.59-5.83-3.42-9.47c0.13-2.65-0.41-5.11-1.85-7.4c-1.37-2.18-1.6-4.65-0.96-7.11c0.65-2.47,0.43-4.84-0.31-7.23
                        c-0.93-3.03-0.96-6.05,0.26-9.01c0.72-1.77,0.87-3.53,0.34-5.39c-1.19-4.23-0.85-8.35,0.9-12.38c0.91-2.11,1.24-4.3,0.91-6.6
                        c-0.57-3.87-0.35-7.68,1.96-11.01c1.71-2.46,2.12-5.05,1.81-7.95c-0.38-3.77,0.59-7.17,3.34-9.92c1.41-1.41,2.11-3.11,2.31-5.09
                        c0.37-3.37,1.37-6.57,3.77-9.1c1.68-1.77,2.59-3.83,2.75-6.21c0.16-2.37,1.07-4.33,2.83-5.93c1.93-1.77,3.08-3.93,3.36-6.58
                        c0.32-3.05,1.6-5.62,4.17-7.45c2.87-2.05,4.71-4.83,6.04-8.05c1.72-4.18,4.76-7.17,9.07-8.6c2.75-0.91,4.68-2.64,6.36-4.87
                        c1.22-1.63,2.55-3.21,3.92-4.73c1.71-1.9,3.72-3.77,6.2-4.14c3.87-0.57,6.04-3.06,8.32-5.74c3.44-4.06,7.42-7.83,12.73-8.79
                        c6.14-1.1,10.86-4.48,15.77-7.76c0.22-0.15,0.49-0.25,0.71-0.41c2.99-1.99,6.24-2.61,9.75-1.94c1.05,0.19,2.06,0.15,2.94-0.46
                        c4.08-2.78,8.63-4.31,13.43-5.23C146.85,103.66,147.73,103.6,148.04,102.52z M283.58,22.57c-3.67,0.79-7.27,1.84-10.91,2.74
                        c-10.72,2.65-21.21,6.01-31.5,9.91c-14.85,5.62-29.24,12.34-42.91,20.48c-11.07,6.58-21.71,13.75-30.47,23.35
                        c-3.37,3.7-6.45,7.66-7.71,12.6c-1.47,5.73-0.31,8.82,4.87,12.26c0.46,0.29,0.96,0.53,1.44,0.77c3.72,1.81,7.71,2.64,11.78,3.09
                        c13.68,1.53,27.15-0.35,40.51-2.96c14.78-2.89,29.15-7.32,43.28-12.56c14.02-5.21,27.65-11.26,40.71-18.55
                        c12.13-6.77,23.75-14.29,33.57-24.28c3.74-3.81,7.13-7.91,8.99-13.01c2.25-6.14,0.5-10.79-5.26-13.9
                        c-5.14-2.77-10.79-3.56-16.5-3.9C309.95,17.82,296.73,19.92,283.58,22.57z M520.2,88.54c-0.81-1.31-1.93-2.09-3.05-2.86
                        c-4.43-3.05-9.36-5.09-14.47-6.57c-31.09-8.99-62.7-14.49-95.21-13.68c-5.14,0.13-10.31,0.18-15.62,1.06
                        c1.19,2.22,2.31,4.28,3.39,6.37c0.41,0.79,1.03,0.66,1.74,0.63c3.27-0.1,6.54-0.34,9.79-0.22c20.43,0.79,40.69,3.22,60.79,6.99
                        c11.84,2.22,23.26,5.79,33.95,11.5c3.55,1.9,6.82,4.23,10.1,6.76C514.54,95.1,517.36,91.82,520.2,88.54z M313.54,102.88
                        c0.62-0.28,1.05-0.44,1.44-0.66c5.36-2.94,10.6-6.08,15.72-9.42c12.75-8.29,24.89-17.31,35.01-28.8
                        c4.45-5.03,8.36-10.41,10.35-16.96c0.37-1.19,0.26-2.13-0.56-3.17c-2.8-3.59-5.48-7.27-8.23-10.89c-0.28-0.37-0.43-1-1.13-0.93
                        c0.63,4.14,0.15,7.66-1.6,11.56c-2.83,6.33-7.14,11.59-12.03,16.37c-12.68,12.43-27.44,21.96-42.84,30.61
                        c-0.44,0.25-1.03,0.32-1.19,1.18c3.71,0.75,3.27,3.28,2.22,5.93C313.85,98.17,313.85,98.17,313.54,102.88z M305.47,96.68
                        c-1.49-0.9-2.62-2.5-4.61-1.47c-7.3,3.77-14.78,7.17-22.36,10.35c-12.22,5.14-24.69,9.61-37.39,13.4
                        c-3.75,1.12-7.64,1.8-11.44,3.37c3.05,1.84,0.38,3.87,0.35,5.8c3.12,1.19,3.09,1.19,2.15,4.48c-0.31,1.09-0.9,2.12-0.84,3.42
                        c0.44-0.1,0.78-0.15,1.1-0.24c8.54-2.31,16.97-4.95,25.32-7.89c15.66-5.51,30.86-12.06,45.74-19.43c2.91-1.44,3.9-3.33,3.97-6.2
                        C304.27,101.54,304.27,101.54,305.47,96.68z M220.94,134.57c-3.56-3.15-3.56-3.15-1.62-6.58c0.37-0.65,0.88-1.24,0.1-1.99
                        c-0.68-0.65-1.16-1.41-2.37-1.13c-4.92,1.12-9.91,1.77-14.9,2.47c-8.49,1.19-17.03,1.9-25.57,1.21
                        c-9.66-0.78-19.34-1.88-25.82-11.03c-0.15,4.14,0.52,7.93,0.62,11.76c0.1,3.95,2.33,6.36,5.33,8.36c3.83,2.55,8.13,3.83,12.59,4.55
                        c16.34,2.68,32.39,0.13,48.42-2.81c0.57-0.1,1-0.34,1.27-0.88C219.59,137.21,220.25,135.94,220.94,134.57z M241.4,197.08
                        c-2.94-9.75-5.54-19.39-8.1-29.21c-11.37,2.5-22.73,4.17-34.42,4.73c2.05,9.48,4.03,18.61,5.98,27.72c0.22,1.02,0.87,1,1.68,0.97
                        c5.23-0.1,10.45-0.46,15.65-1.06C228.54,199.51,234.88,198.72,241.4,197.08z M250.19,226.44c-5.29,0.63-10.35,1.27-15.4,1.83
                        c-1.05,0.12-1.5,0.77-1.96,1.55c-3.44,5.89-6.45,12-9.25,18.21c-0.26,0.57-0.25,1.38-1.72,1.63c2.84-7.46,6.32-14.27,10.28-21.08
                        c-1.96,0.12-3.68,0.22-5.11,0.31c-2.99,5.18-5.21,10.45-7.68,15.62c1.03-5.51,3.33-10.5,6.2-15.6c-4.67,0.31-9.1,0.04-13.75,0.04
                        c1.97,7.7,3.89,15.15,5.76,22.6c0.21,0.81,0.81,0.84,1.38,0.94c3.49,0.59,6.98,1.15,10.5,1.46c1.31,0.12,1.88-0.28,2.41-1.5
                        c2.15-5.01,4.61-9.88,7.39-14.57c0.54-0.91,1.02-1.97,2.58-2.38c-3.67,6.15-6.82,12.17-9.78,18.58c1.22,0.07,2.05,0.03,2.83,0.18
                        c1.75,0.31,2.58-0.34,3.22-2.08c1.87-5.06,4.4-9.82,7.46-14.27c0.22-0.31,0.35-0.79,1.18-0.35c-2.89,5.45-6.24,10.7-8.41,16.69
                        c7.11,0.21,13.99,0.15,21.07-0.66C255.98,244.47,253.03,235.52,250.19,226.44z M205.63,203.06c0.04,0.56,0.01,0.9,0.1,1.22
                        c1.52,6.3,2.94,12.62,4.58,18.87c1.07,4.09,1.22,4.05,5.54,4.15c0.34,0.01,0.69-0.03,1.02,0.03c1.46,0.24,2.15-0.43,2.74-1.75
                        c2.5-5.59,5.06-11.17,8.33-16.39c0.35-0.57,0.54-1.38,1.85-1.58c-3.64,6.51-6.89,12.81-9.55,19.49c1.66,0.47,2.69,0.28,3.4-1.52
                        c1.53-3.86,3.25-7.64,5.03-11.39c0.78-1.65,1.44-3.43,2.93-4.71c-2.58,5.83-5.3,11.59-7.6,17.72c8.76-0.28,17.15-1.27,25.63-2.68
                        c-2.59-8.61-5.09-16.99-7.61-25.37c-4.31,0.63-8.42,1.25-12.53,1.83c-0.6,0.09-0.87,0.4-1.16,0.93c-3.53,6.3-7.08,12.6-10.14,19.15
                        c-0.22,0.49-0.21,1.3-1.55,1.09c2.72-7.27,6.33-13.99,10.5-20.73C219.79,202.35,212.77,202.9,205.63,203.06z M218.57,144.17
                        c-4.59,8.45-8.83,17.02-12.57,26c9.29-0.56,18.06-2.15,26.98-4.3c-2.43-8.94-4.31-17.7-6.12-26.72
                        c-10.89,2.66-21.66,4.65-32.67,5.68c1.09,8.16,2.46,15.93,4.06,23.66c0.16,0.75-0.31,1.96,1.07,2.08c1.19,0.1,1.34-0.88,1.71-1.68
                        c2.47-5.31,4.93-10.63,7.49-15.9c0.54-1.13,0.96-2.49,2.38-3.08c-3.27,6.71-6.32,13.5-9.13,20.33c2.15,0.65,3.06-0.21,3.83-2.05
                        c2.18-5.23,4.71-10.29,7.38-15.27C214.63,149.88,216.19,146.78,218.57,144.17z M488.7,126.51c2.97-3.74,5.67-7.15,8.38-10.56
                        c1.87-2.34,1.88-2.37-0.53-4.21c-4.49-3.44-9.61-5.7-14.91-7.52c-22.17-7.6-45.09-11.44-68.32-13.79
                        c-2.99-0.31-5.99-0.53-9.19-0.34c1.19,2.28,2.02,4.43,3.34,6.33c0.44,0.65,0.88,1.16,1.78,1.12c9.25-0.46,18.33,1.31,27.46,2.19
                        c11.09,1.07,21.98,3.19,32.23,7.86C477.58,111.52,485.09,116.73,488.7,126.51z M398.09,117.17c-4.27,5.3-8.77,10.1-13.69,14.52
                        c-0.54,0.49-0.31,0.94-0.1,1.46c3.7,8.69,6.93,17.55,10.36,26.48c2.16-1.46,4.23-2.83,3.84-5.89c-0.1-0.87-0.04-1.77,0.06-2.64
                        c0.26-2.02-0.31-4.09,0.49-6.09c0.93,3.36,0.44,6.8,0.52,10.44c5.23-4.98,10-10.01,14.03-15.69c0.54-0.77,0.6-1.4,0.24-2.25
                        c-2.59-6.14-5.09-12.32-7.74-18.45c-1.13-2.64-2.08-5.39-3.96-7.77c-0.34,5.24,0.97,10.33,1.22,15.5
                        c0.25,5.18,1.12,10.33,0.35,15.55c-1.05-9.35-1.94-18.68-3.08-28.37c-1.02,0.97-1.41,1.58-1.3,2.62c0.32,2.96,0.59,5.95,0.72,8.92
                        c0.09,2.21,0.5,4.48-0.43,6.67C399.1,127.26,398.97,122.35,398.09,117.17z M369.85,101.89c4.76,9.35,9.26,18.74,13.35,28.5
                        c2.9-2.55,5.4-5.14,7.88-7.77c0.46-0.49,0.57-1,0.52-1.66c-0.94-8.86-1.96-17.71-3.59-26.48c-0.32-1.78-0.43-3.61-0.62-5.4
                        c0.22-0.03,0.44-0.04,0.66-0.07c2,10.36,3.8,20.77,4.89,31.37c0.82-1.28,2.61-1.83,2.31-3.7c-0.72-4.64-1.43-9.27-2.12-13.93
                        c-0.09-0.6-0.62-1.27,0.28-2.11c1.68,5.18,1.88,10.47,2.74,16.22c1.78-2.61,3.24-4.78,4.74-6.93c0.57-0.82,0.57-1.52,0.1-2.44
                        c-1.66-3.19-3.18-6.48-4.86-9.67c-2.78-5.27-5.65-10.5-8.6-15.97c-2.68,3.65-5.09,7.11-7.98,10.19c-0.35,0.38-0.37,0.82-0.26,1.32
                        c1.6,7.61,2.18,15.34,2.86,23.05c0.21,2.36,0.72,4.73,0.16,7.13c-1.38-9.85-2.38-19.76-4.14-29.62c-1.59,0.91-1.9,1.94-1.5,3.43
                        c0.44,1.63,0.71,3.36,0.78,5.03c0.09,1.96,0.69,3.93-0.1,5.92c-0.88-3.77-0.74-7.7-2.02-11.64
                        C373.43,98.48,371.68,100.15,369.85,101.89z M415.9,275.3c1.9-0.82,2.66-2.52,3.58-4.06c7.46-12.87,13.96-26.23,20.11-39.78
                        c4.9-10.79,8.85-22.05,14.84-32.33c15.81-27.1,31.67-54.15,52.01-78.28c10.98-13.03,20.89-26.94,32.43-39.51
                        c5.89-6.42,12.28-12.19,20.26-15.91c4.55-2.12,9.14-3.37,13.51,0.5c0.07,0.06,0.34-0.12,0.65-0.24c-0.97-2.46-3.03-3.21-5.26-3.61
                        c-3.37-0.59-6.49,0.44-9.48,1.81c-7.58,3.47-14.19,8.32-19.96,14.31c-6.62,6.89-12.76,14.19-18.68,21.69
                        c-10.04,12.75-20.65,25.06-30.31,38.1c-7.76,10.47-14.28,21.79-21.24,32.8c-9.44,14.94-18.46,30.11-25.22,46.52
                        c-6.95,16.9-14.88,33.37-22.79,49.83C419.02,269.94,417.4,272.59,415.9,275.3z M293.02,215.78c2.83,9.63,5.83,18.9,9.75,28.03
                        c7.48-2.4,14.77-4.84,22.16-8.29c-4.65-2.61-8.88-4.99-13.28-7.46c2.46-1.38,2.56-3.77,0.44-4.98c-1.16-0.66-1.24-1.41-0.79-2.53
                        c1.62-4.03,1.75-8.51,4.09-12.85C307.71,210.7,300.48,213.23,293.02,215.78z M78.92,288.74c0.06,0.91-0.01,1.28,0.1,1.55
                        c2.93,6.21,5.98,12.35,10.25,17.78c10.14,12.94,22.01,23.91,36.48,31.96c9.3,5.18,18.64,10.26,28.6,14
                        c11.9,4.46,24.41,5.86,36.94,6.95c13.44,1.18,26.85,1.1,40.18-1.35c10.33-1.9,20.48-4.59,30.41-7.95
                        c6.62-2.25,13.22-4.61,19.9-6.64c6.71-2.03,13.32-4.33,19.76-7.04c9.5-4,18.71-8.58,27.69-13.65c14.1-7.96,26.91-17.77,39.57-27.79
                        c0.24-0.19,0.63-0.32,0.47-0.78c-0.12-0.03-0.29-0.13-0.37-0.09c-0.68,0.43-1.38,0.84-2,1.35c-26.97,22.13-57.02,38.54-90.44,48.61
                        c-10.35,3.12-20.63,6.57-31.4,8.19c-7.8,1.18-15.56,2.66-23.5,2.49c-22.83-0.52-45.65-1.25-67.85-7.46
                        c-0.66-0.18-1.37-0.57-2.15,0.16c15.49,6.04,31.86,6.73,48.17,7.93c16.56,1.21,33.01,0.79,49.32-2.62
                        c-7.35,1.84-14.66,3.71-22.19,4.71c-11.01,1.47-22.04,1.59-33.09,0.75c-18.9-1.44-37.33-4.53-54.38-13.69
                        c-11.81-6.35-23.47-12.66-33.45-21.82c-9.45-8.69-17.75-18.17-23.45-29.77C81.64,292.65,80.85,290.7,78.92,288.74z M293.65,148.49
                        c-5.84,2.31-11.04,4.49-16.33,6.4c-1.53,0.56-1.99,1.32-1.63,2.66c0.66,2.49,1.13,5.03,1.72,7.54c1.19,5.02,2.81,9.94,4.28,14.9
                        c0.56,1.87,1.19,3.7,1.83,5.68c7.11-2.3,13.88-4.96,20.83-7.86c-4.64-3.58-9.91-5.56-14.68-8.8c1.87-0.53,1.75-1.94,2.02-3.11
                        c0.24-1.05-0.69-1.34-1.41-1.65c-1.27-0.56-1.44-1.4-0.94-2.64C291.03,157.48,291.08,152.86,293.65,148.49z M415.22,166.87
                        c-0.37-7.92-0.91-15.53-1.97-23.63c-5.39,6.76-11.03,12.35-17.09,17.53c-0.57,0.49-0.78,0.88-0.46,1.72
                        c2.55,6.42,5.02,12.88,7.55,19.46c1.69-1.1,2.8-2.66,4.03-4.06c4.65-5.26,9.32-10.51,12.69-16.74c0.5-0.93,1.25-1.78,1.02-3
                        c-1.13-5.99-3.7-11.47-5.89-17.15c-0.66,0.52-1.1,0.93-0.87,1.66c0.65,2.03,0.69,4.17,0.91,6.26c0.53,5.06,1.15,10.11,1.16,15.22
                        C416.33,164.99,416.46,166.03,415.22,166.87z M284.07,187.6c2.78,8.79,5.54,17.43,8.33,26.26c6.7-2.13,13.12-4.4,19.82-7.08
                        c-4.02-2.24-7.71-4.31-11.53-6.43c2.41-1.56,2.46-3.75,0.26-5.09c-1.05-0.63-1.12-1.35-0.71-2.38c1.62-4.05,1.75-8.52,4.08-12.87
                        C297.38,182.73,290.96,185.26,284.07,187.6z M346.55,20.35c-0.01,0.81,0.57,1.03,0.9,1.41c2.64,3.05,3.71,6.55,3.31,10.56
                        c-0.52,5.14-3.03,9.39-5.92,13.49c-0.43,0.59-1.05,1.09-0.91,1.97c0.24,1.53,0.46,3.06,0.68,4.62c1,0.16,1.9-0.22,2.8-0.46
                        c1.77-0.46,2.46,0.32,2.41,1.96c-0.04,1.34-0.19,2.68-0.31,4.02c1.5,0.4,2.53-0.13,3.42-1.25c0.81-1.02,1.75-1.91,2.58-2.9
                        c3.84-4.55,7.32-9.33,8.89-15.21c1.52-5.61,0.13-10.08-4.24-12.93C356.04,22.92,351.36,21.55,346.55,20.35z M400.94,83.81
                        c1.31,2.15,1.38,4.59,4.7,4.55c21.51-0.28,42.6,3.12,63.42,8.1c11.47,2.74,22.82,6.11,31.62,14.96c0.94-1.18,1.85-2.33,2.89-3.62
                        c-6.39-4.45-12.84-8.33-20.12-10.63c-23.26-7.32-47.09-11.64-71.4-13.4C408.48,83.5,404.89,83.39,400.94,83.81z M293.18,146.44
                        c-2.64-1.88-5.06-3.56-7.79-4.84c-3.52-1.66-3.46-1.75-2.68-5.43c0.04-0.24,0.04-0.49,0.06-0.69c-2.9-1.56-2.84-1.53-1.91-4.5
                        c1.15-3.68,2.19-7.4,3.36-11.35c-5.54,2.3-10.82,4.48-16.22,6.71c2.11,9.25,3.71,18.49,6.49,27.56
                        C280.74,151.53,286.94,149.27,293.18,146.44z M171.71,226.44c-0.97,1.37-0.19,2.22,0.1,2.99c3.67,9.38,10.88,15.28,19.9,18.74
                        c10.92,4.18,22.26,7.11,33.9,8.57c8.13,1.02,16.3,0.72,24.48,0.59c7.38-0.12,14.65-0.88,21.83-2.37c6.2-1.28,12.4-2.64,18.53-4.21
                        c4.61-1.18,9.32-2.05,13.78-3.67c10.07-3.65,19.93-7.83,29.58-12.44c10.95-5.23,21.45-11.32,31.45-18.27
                        c6.79-4.71,14.15-8.63,20.51-13.93c8.41-7.01,16.47-14.41,23.98-22.41c6.52-6.95,11.7-14.68,14.69-23.79
                        c0.32-0.99,0.93-2.02,0.22-3.24c-0.65,1.87-1.15,3.65-1.87,5.31c-4.62,10.67-12.59,18.86-20.45,27.09
                        c-10.16,10.64-22.17,18.99-34.21,27.32c-13.31,9.2-27.19,17.42-41.87,24.2c-14.94,6.9-30.71,11.44-46.7,15.18
                        c-6.24,1.46-12.54,2.64-18.92,3.25c-6.15,0.59-12.34,0.47-18.52,0.63c-17.39,0.49-34.14-2.5-50.17-9.16
                        c-6.49-2.69-12.68-6.11-16.65-12.35C173.83,232.1,172.74,229.5,171.71,226.44z M172.4,170.42c2.31,1.83,1.94,3.92,0.94,6.11
                        c-0.22,0.49-0.31,1.05-0.53,1.55c-0.32,0.71-0.28,1.25,0.32,1.81c2.28,2.16,2.84,4.64,1.34,7.51c-0.62,1.16-0.31,2.13,0.66,3.08
                        c2.08,1.97,3.59,4.2,2.03,7.39c1.8,0.47,3.53,1.02,5.31,1.4c4.99,1.1,10.04,1.63,15.13,1.94c1.72,0.1,2.69-0.4,3.27-1.93
                        c0.22-0.57,0.49-1.12,0.71-1.65c-1.15-1.35-3.5-1.87-3.47-3.8c0.03-1.66,1.05-3.31,1.63-4.95c-4.3-3.75-4.3-3.75-2.15-8.04
                        c-0.79-0.65-1.55-1.32-2.37-1.91c-1.25-0.9-1.6-1.85-0.66-3.27c0.57-0.87,1.3-1.75,1.35-3.08
                        C188.08,172.47,180.31,171.85,172.4,170.42z M529.71,75.82c0.04-0.19,0.09-0.38,0.15-0.57c-0.15-0.21-0.28-0.54-0.47-0.59
                        c-3.19-0.71-5.98-2.43-8.99-3.56c-1.21-0.46-2.55-0.29-3.84-0.09c-2.06,0.31-3.99,0.12-5.89-1c-2.33-1.35-4.89-2.02-7.66-1.58
                        c-2.03,0.32-4.05,0.25-5.98-0.72c-4.28-2.18-8.86-2.58-13.53-1.94c-1.52,0.21-2.8-0.03-4.09-0.75c-3.03-1.69-6.29-2.13-9.69-1.46
                        c-3.19,0.63-6.17,0.28-8.95-1.65c-2.94-2.03-6.27-2.49-9.78-1.85c-1.35,0.25-2.77,0.43-4.03-0.16c-3.89-1.83-7.85-1.25-11.82-0.5
                        c-1.56,0.29-2.99,0.18-4.33-0.63c-3.42-2.03-7.1-2.19-10.88-1.52c-1.02,0.18-2.06,0.43-2.99-0.13c-3.44-2.09-7.05-1.68-10.69-0.75
                        c-1.72,0.44-3.43,0.43-4.98-0.41c-2.72-1.46-5.21-1.25-7.6,0.68c-1.1,0.88-2.22,0.88-3.36-0.01c-0.37-0.29-0.79-0.52-1.22-0.74
                        c-0.63-0.32-1.32-0.6-1.84,0.1c-0.46,0.62,0.01,1.15,0.4,1.63c2.19,2.69,4.53,3.03,7.21,0.81c1.43-1.19,2.62-1.34,4.18-0.31
                        c2.16,1.43,4.56,1.63,7.07,0.87c1.37-0.41,2.78-0.69,4.17-1.03c1.12-0.26,2.25-0.13,3.11,0.6c2.52,2.13,5.24,2.21,8.24,1.28
                        c2.8-0.87,5.51-0.53,8.01,1.13c1.8,1.18,3.74,1.37,5.87,0.91c3.36-0.72,6.71-1.06,9.98,0.62c1.21,0.62,2.64,0.91,3.99,0.49
                        c4.15-1.3,7.91-0.54,11.47,1.83c1.71,1.15,3.65,1.43,5.68,1.15c4.4-0.6,8.67-0.6,12.53,2.11c0.97,0.68,2.18,0.69,3.36,0.38
                        c4.76-1.25,9.29-0.74,13.63,1.6c1.32,0.71,2.8,1.22,4.33,0.75c3.18-0.97,6.05-0.29,8.72,1.55c1.91,1.32,3.93,1.77,6.2,1.02
                        c2.4-0.78,4.61-0.34,6.48,1.35C524.34,76.97,527.11,75.39,529.71,75.82z M317.04,172.33c4.53-2.21,8.61-4.18,12.68-6.18
                        c0.99-0.49,0.5-1.21,0.24-1.91c-1.75-4.43-3.43-8.88-5.23-13.29c-1.94-4.78-3.96-9.52-5.99-14.38c-4.89,2.43-9.6,4.77-14.6,7.26
                        c4.14,2.24,7.98,4.36,11.87,6.39c1.52,0.79,2.13,2.15,1.27,3.59c-0.4,0.66-0.85,1.16-0.54,1.94c3.06,1.05,3.93,2.16,2.66,5.18
                        C317.85,164.47,318.07,168.18,317.04,172.33z M149.86,136.77c-2.03,1.22-4.02,2.27-5.86,3.53c-6.88,4.74-13.69,9.54-20.24,14.75
                        c-5.67,4.5-11.1,9.19-15.8,14.72c-5.23,6.14-9.91,12.66-13.96,19.64c-7.73,13.37-13.78,27.38-15.65,42.85
                        c-1.31,10.82-1.59,21.69,0.47,32.48c0.94,4.96,2.22,9.82,3.97,14.57c3.43,9.27,7.86,18.02,13.88,25.91
                        c7.66,10.05,17.14,18.21,26.9,26.06c0.97,0.78,1.97,1.77,3.75,1.83c-0.43-0.46-0.57-0.66-0.77-0.81
                        c-7.49-5.9-14.66-12.16-21.33-19.01c-15.66-16.11-23.94-35.69-26.1-57.75c-1.63-16.66,1.07-32.9,7.54-48.46
                        c6.64-15.99,15.52-30.47,28.15-42.44c10.48-9.92,22.14-18.34,34.38-25.98C149.84,138.28,150.62,137.96,149.86,136.77z
                        M456.64,82.27c0.07-0.16,0.15-0.32,0.21-0.49c1.91,0.38,3.83,0.74,5.74,1.15c1.99,0.43,3.97,0.87,5.95,1.37
                        c1.91,0.49,3.8,1.03,5.68,1.59c1.96,0.57,3.9,1.18,5.84,1.81c1.87,0.6,3.75,1.21,5.61,1.88c1.91,0.71,3.81,1.46,5.7,2.27
                        c1.81,0.78,3.61,1.6,5.37,2.47c1.83,0.9,3.68,1.8,5.43,2.86c4.18,2.5,4.15,2.53,4.83,2.15c-0.49-0.44-0.94-0.88-1.55-1.43
                        c0.97-0.13,1.49,1.12,2.56,0.25c-1.52-1.37-3.25-2.27-4.92-3.22c-11.88-6.8-24.64-11.01-38.06-13.65
                        c-19.98-3.93-40.09-6.65-60.48-6.92c-1.9-0.03-3.83-0.35-5.71,0.06c0.12,1.5,0.94,1.87,2.11,1.87c11.39-0.09,22.69,1.09,33.93,2.78
                        c24.32,3.68,48.02,9.35,69.5,21.99c0.34,0.19,0.74,0.77,1.37-0.26C490.33,92.03,474.02,85.77,456.64,82.27z M148.23,347.11
                        c-0.87-1.02-1.78-1.28-2.62-1.65c-3.43-1.49-6.96-2.78-10.31-4.46c-17.92-8.97-32.45-21.82-43.66-38.39
                        c-5.43-8.01-10.31-16.36-12.62-25.85c-2.52-10.33-3.87-20.8-3.59-31.52c0.68-25.76,9.42-48.6,24.88-68.85
                        c8.76-11.47,20.4-20.11,31.46-29.22c5.29-4.36,11.11-8.01,16.9-11.7c0.52-0.32,1.58-0.49,0.93-1.75c-0.52,0.32-0.99,0.65-1.49,0.96
                        c-11.69,7.17-22.16,15.88-32.48,24.86c-11.32,9.85-20.95,20.89-27.74,34.42c-6.3,12.57-11.29,25.51-13.03,39.51
                        c-1.22,9.86-1.47,19.77,0.15,29.65c1.59,9.76,3.49,19.37,8.3,28.24c7.92,14.6,18.2,27.25,31.03,37.72
                        c8.82,7.2,18.64,12.79,29.46,16.52C145.17,346.05,146.38,347.01,148.23,347.11z M328.81,199.91c4.02-1.9,7.98-3.75,12.06-5.67
                        c-3.05-9.07-6.29-17.83-9.72-26.66c-5.24,2.55-10.26,4.98-15.69,7.61c3.3,1.77,6.32,3.36,9.29,4.99c3.83,2.09,3.83,2.11,2.69,6.18
                        c-0.03,0.12,0.04,0.25,0.07,0.37c3.33,1.47,4.03,2.87,2.56,6.15C329.12,195.12,328.7,197.35,328.81,199.91z M168.58,144.25
                        c2.52,1.72,2.02,3.62,0.81,5.68c-0.46,0.78-0.88,1.68-0.84,2.64c3.39,2.61,3.62,2.97,2.02,5.92c-0.9,1.65-0.6,2.65,0.56,3.9
                        c1.32,1.44,2.89,3.02,0.49,5.21c7.86,1.87,15.49,2.74,23.44,2.62c-2.59-1.44-2.53-3.18-1.07-5.17c0.74-1-0.01-1.55-0.62-2.21
                        c-2.41-2.61-2.58-3.22-0.9-6.27c0.69-1.25,0.37-1.99-0.44-2.84c-2.08-2.22-2.97-4.62-1.07-7.45c0.19-0.28,0.47-0.71-0.01-1.25
                        C183.61,145.47,176.19,145.38,168.58,144.25z M204.57,211.17c-4.34-3.11-4.34-3.11-3.14-7.99c-7.79-0.6-15.53-1.1-23.5-3.49
                        c1.3,1.53,2.65,2.36,2.09,4.11c-0.43,1.34-0.74,2.71-1.07,4.02c4.2,2.38,3.47,1.43,2.49,7.51c3.11,2.56,3.11,2.56,1.84,5.33
                        c4.98,2.64,19.59,6.27,22.83,5.67c-2.11-1.85-2.11-1.85,0.21-6.01c-1.15-1.52-3.43-2.12-3.96-3.99
                        C201.79,214.34,203.75,212.97,204.57,211.17z M349.54,222.65c-2.41-8.98-5.17-17.75-8.07-26.56c-4.96,2.33-9.75,4.56-14.85,6.95
                        c3.24,1.74,6.21,3.31,9.17,4.92c3.84,2.09,3.83,2.11,2.71,6.18c-0.03,0.12,0.04,0.25,0.07,0.37c3.28,1.46,4.17,3.46,2.46,6.55
                        c-1.25,2.27-1.1,4.59-1.3,7.36C343.15,226.41,346.28,224.57,349.54,222.65z M78.91,286.65c-0.54-1.59-0.85-2.62-1.25-3.61
                        c-3.42-8.46-5.4-17.3-6.24-26.34c-0.97-10.48,0.06-20.93,2.27-31.2c2.68-12.51,8.27-23.88,14.65-34.89
                        c7.21-12.47,15.65-23.94,26.96-33.08c10.84-8.76,21.18-18.14,33.24-25.26c0.77-0.46,1.35-0.87,0.59-1.88
                        c-4.49,2.58-8.83,5.24-12.85,8.38c-5.95,4.65-11.91,9.29-17.72,14.1c-6.92,5.73-13.72,11.63-19.2,18.84
                        c-5.99,7.89-11.28,16.22-15.87,25.01c-6.21,11.91-10.84,24.26-12.78,37.69c-2.46,17.02-0.72,33.34,5.99,49.16
                        C77.11,284.53,77.3,285.74,78.91,286.65z M208.75,228.5c-8.38-0.44-16.24-2.84-24.36-5.87c1.93,2.62,1.94,2.62,1.47,5.4
                        c-0.12,0.66,0.15,0.97,0.63,1.34c2.97,2.3,2.96,2.31,1.58,5.82c-0.09,0.24-0.13,0.5-0.22,0.87c3,1.97,4.87,4.4,2.93,8.19
                        c6.62,2.78,13.25,4.74,20.21,6.52c-0.53-1.02-1.1-1.75-0.07-2.52c0.69-0.52,1.62-0.71,2.16-1.59c-1.37-1.15-3.05-2.06-3.97-3.46
                        c-1.25-1.87,1.15-3.05,1.56-4.71c-0.94-1.75-3.56-2.12-3.96-4.14C206.29,232.27,208.07,230.62,208.75,228.5z M164.22,190.53
                        c2.78,2.61,5.95,4.58,9.85,6.11c-2.37-9.04-3.68-17.87-5.51-26.6c-0.1-0.47-0.18-0.94-0.74-1.1c-3.7-1.12-7.18-2.75-10.54-4.52
                        c1.16,5.37,2.28,10.56,3.52,16.31c2.02-3.42,3.18-6.57,5.18-9.5c0.5,0.84,0.19,1.16,0.01,1.52c-1.22,2.5-2.34,5.08-3.7,7.51
                        c-1.02,1.83-0.59,3.42,0.15,5.31c1.85-3.11,2.75-6.43,4.8-9.13c-1.13,3.19-2.47,6.29-3.87,9.36c-0.47,1.03-0.66,1.96,0.21,2.91
                        c1.21-1.47,1.37-3.46,3.24-5.09C166.28,186.44,164.76,188.28,164.22,190.53z M152.01,136.44c0.88,5.17,1.77,10.32,2.71,15.88
                        c1.8-2.78,2.62-5.67,4.78-7.71c-1.15,2.89-2.47,5.67-3.93,8.38c-0.53,1-0.54,1.8,0.03,2.91c2.46-4.3,4.23-8.76,7.13-12.53
                        c-1.94,4.45-4.09,8.76-6.26,13.09c-0.56,1.1-0.79,2.22-0.24,3.74c1.24-2.5,2.3-4.77,3.44-6.99c1.16-2.22,2.12-4.56,3.99-6.39
                        c-1.81,5.05-4.46,9.75-6.2,14.93c3.39,1.87,6.74,3.53,10.47,4.64c-0.94-7.45-1.9-14.6-2.72-21.76c-0.12-1.07-0.46-1.5-1.46-1.8
                        C159.46,141.58,155.55,139.61,152.01,136.44z M68.63,258.57c0.49-1.34-0.15-2.55-0.21-3.83c-0.22-5.55-0.09-11.1,0.06-16.65
                        c0.34-13.19,2.84-25.9,8.42-37.89c4.02-8.64,9.69-16.25,15.44-23.79c15.09-19.74,34.01-34.86,55.96-46.3
                        c0.57-0.29,1.16-0.52,0.63-1.49c-0.93,0.31-1.91,0.52-2.83,0.94c-7.85,3.68-14.97,8.61-21.96,13.62
                        c-14.28,10.23-26.35,22.7-36.58,37.02c-8.01,11.2-14.44,23.02-17.5,36.57c-2.64,11.62-2.93,23.38-2.66,35.18
                        C67.44,254.22,67.51,256.51,68.63,258.57z M317.93,134.82c-3.77-8.82-7.89-17.18-13.13-25.06c-3.55,1.77-6.99,3.47-10.63,5.29
                        c3.49,1.87,6.68,3.55,9.85,5.27c3.42,1.87,3.4,1.88,2.44,5.68c-0.03,0.12,0.04,0.25,0.07,0.37c3.12,2.03,3.11,2.03,2.15,5.82
                        c-0.63,2.53-1.34,5.05-1.37,7.96C310.98,138.31,314.41,136.59,317.93,134.82z M504.04,103.42c0.1-0.22,0.21-0.43,0.29-0.65
                        c-0.49-0.32-0.96-0.68-1.46-0.99c-5.17-3.24-10.86-5.34-16.39-7.83c-12.78-5.74-26.18-9.57-39.88-12.12
                        c-14.28-2.64-28.8-3.5-43.28-4.33c-1.12-0.06-2.25-0.21-3.39,0.41c25.82,2.06,51.75,3.37,76.23,13.06
                        c-3.55-0.77-7.05-1.68-10.57-2.58c-18.99-4.89-38.26-7.77-57.87-8.36c-2.21-0.07-4.52-0.29-7.14,0.85
                        c2.47,0.62,4.49,0.15,6.46,0.16c16.06,0.13,31.9,2.38,47.6,5.52c13.23,2.65,26.13,6.52,38.1,12.96c3.33,1.8,6.48,3.93,9.7,5.92
                        c0.19-0.25,0.38-0.5,0.57-0.75c-2.84-2.5-6.42-4.06-9.42-6.39C497.4,99.37,500.51,101.86,504.04,103.42z M541.26,90.73
                        c-5.96,6.68-11.22,13.41-16.78,20.6c6.29-0.29,12.13-0.41,18.03,0.06C542.14,104.47,542.07,97.81,541.26,90.73z M130.12,335.67
                        c1.85,1.58,3.43,2.52,5.05,3.36c14.12,7.38,29.25,11.26,45.02,12.84c13.74,1.37,27.5,1.06,41.26,0.66
                        c11.29-0.34,22.51-1.37,33.61-3.47c5.83-1.1,11.54-2.64,17.18-4.48c6.85-2.22,13.81-4.12,20.68-6.3c1.47-0.47,3.14-0.78,4.67-2.34
                        c-2,0.69-3.52,1.25-5.05,1.72c-3.71,1.13-7.45,2.15-11.13,3.36c-6.6,2.16-13.23,4.21-19.98,5.8c-10.16,2.38-20.52,3.53-30.92,4.11
                        c-15.78,0.87-31.58,1.15-47.37-0.07c-17.36-1.34-33.9-5.54-49.38-13.65C132.8,336.67,131.98,335.79,130.12,335.67z M164.59,108.93
                        c-7.46-4.73-6.39-3.44-10.32-10.04c-2.12,2.96-3.3,10.73-3.18,13.47c0.22,5.2,6.58,11.41,11.56,11.53
                        c-2.49-3.95-2.49-3.95,1.66-7.13c-1.03-1.27-2-2.56-3.09-3.77c-1.16-1.28-0.94-2.09,0.62-2.72
                        C162.78,109.88,163.64,109.38,164.59,108.93z M179.56,228.35c0.04,2.3-0.87,3.77-1.37,5.31c-0.29,0.91-0.4,1.63,0.32,2.43
                        c2.44,2.72,5.49,4.55,8.94,6.43c-0.1-0.68-0.12-1-0.22-1.28c-1.87-5.76-3.71-11.51-5.67-17.24c-0.34-0.99-0.12-2.4-1.22-2.9
                        c-3.14-1.46-6.14-3.15-9.3-5.06c1.22,4.25,2.36,8.2,3.55,12.35c1.44-2.58,2.12-5.36,4.18-7.38c-1,3.42-2.94,6.49-3.49,10.42
                        c1.19-1.18,0.93-2.77,2.33-3.58c0.4,2.13-2.02,3.86-0.63,6.14C177.95,232.15,178.19,230.29,179.56,228.35z M69.37,262.37
                        c-0.24,0.28-0.38,0.38-0.38,0.47c0.06,1.22,0.13,2.43,0.24,3.65c0.62,6.92,2.93,13.41,5.2,19.87c5.45,15.5,14.34,28.74,26.53,39.78
                        c10.25,9.29,22.08,16.12,34.2,22.55c0.29,0.15,0.5,0.09,0.79-0.77c-0.21-0.03-0.6,0.01-0.88-0.13
                        c-6.74-3.61-13.21-7.66-19.51-11.97c-11.92-8.16-22.21-17.93-30.14-30.09c-4.74-7.26-8.14-15.18-11.04-23.29
                        c-2.02-5.62-3.78-11.35-4.27-17.39C70.04,264.24,70.44,263.15,69.37,262.37z M523.07,134.28c-0.32-6.67-0.16-13.15-0.84-20.1
                        c-4.46,6.24-8.63,12.09-13,18.2C514.11,132.57,518.5,133.28,523.07,134.28z M494,154.29c4.34,0.1,8.36,0.6,12.51,0.13
                        c0.49-6.12,0.77-12.2,0.49-18.81C502.39,141.95,497.99,147.78,494,154.29z M556.4,74.86c-4.59,4.09-8.21,8.52-12.57,13.01
                        c4.98,0.1,9.2,1.27,13.71,2.55C557.17,85.21,556.8,80.24,556.4,74.86z M174.1,205.08c0.66,0.71,0.25,1.09,0.09,1.52
                        c-0.84,2.15-1.68,4.28-2.52,6.43c-0.19,0.49-0.62,1.02,0.01,1.46c2.56,1.84,5.27,3.42,8.49,4.92c-1.88-6.89-3.64-13.28-5.46-19.98
                        c-2.19,3.87-4.08,7.54-5.01,11.63c-0.18,0.79,0.32,1.32,0.78,2.05C171.71,210.35,172.52,207.61,174.1,205.08z M345.68,159.63
                        c12.93-7.49,25.47-15.43,36.01-26.09c-0.16-0.21-0.31-0.41-0.47-0.63c-2.81,2.33-5.62,4.65-8.45,6.98
                        c-0.84-1.18,0.22-2.53-0.66-3.55c-0.46,0.29-0.44,0.69-0.4,1.12c0.22,2.36-0.72,3.95-2.68,5.45c-8.17,6.23-16.84,11.66-25.53,17.09
                        c-3.05,1.91-6.29,3.52-9.44,5.27c0.06,0.13,0.13,0.26,0.19,0.4c2.75-1.37,5.51-2.74,8.27-4.11c0.68,1.31,0.03,2.62,0.35,4.05
                        c1.74-1.53-0.4-4.37,2.24-5.49c-0.07,3.95-0.13,7.73-0.19,11.51C345.71,170.35,346.06,162.56,345.68,159.63z M417.31,133.69
                        c2.58-4.06,2.53-4.02-0.1-7.32c-2.22-2.78-4.96-5.24-5.83-8.92c-1.18-4.89-2.36-9.79-3.56-14.68c-0.13-0.54,0.01-1.24-0.69-1.59
                        c-0.96,2.19-1.88,4.34-2.8,6.46c1.02,0.72,2.78,0.4,3.03,1.81c0.19,1.12-0.32,2.36-0.52,3.49c3.39,1.93,3.39,1.93,2.03,5.83
                        c3.46,1.69,3.46,1.69,2.97,5.49c3.86,0.72,3.27,3.3,2.12,5.9C415.39,131.14,417.31,131.25,417.31,133.69z M386.44,162.28
                        c-0.66,1.56-0.47,2.91-0.6,4.21c-0.09,0.88-0.34,1.6-1.13,2.21c-12,9.25-24.57,17.58-38.16,24.35c-1.12,0.56-2.44,0.77-3.64,1.91
                        c12.57-3.74,49.14-27.93,50.55-33.67c-1.62,1.37-2.97,2.53-4.48,3.81c-0.4-2.34,0.34-4.56-0.44-7.14
                        c-0.62,1.87-0.32,3.34-0.41,4.78c-0.21,3.17-0.15,3.18-1.34,3.96C386.32,165.36,387.1,163.93,386.44,162.28z M237.08,166.99
                        c6.04,0.62,32.77-7.14,34.12-9.79c-1.34,0.29-2.59,0.56-4.08,0.88c0.41-2.68,0.79-5.17,1.18-7.67c-1.69,2.58-0.68,6.07-2.96,8.67
                        c-0.46-0.85,0.12-1.47-0.53-1.87c-0.72,0.56-0.34,1.78-1.62,2.11c-5.87,1.47-11.73,3.05-17.56,4.68
                        C242.71,164.83,239.76,165.47,237.08,166.99z M335.37,127.98c11.56-6.52,22.29-14.09,32-23.55c-1.34-0.18-1.63,0.97-2.58,0.99
                        c-0.34-1.81,0.47-3.75-0.49-6.12c-0.56,2.96-0.09,5.46-1.02,8.1c-0.87-1.74,0.06-3.39-0.69-5.12c-0.25,0.52-0.4,0.68-0.41,0.85
                        c-0.07,1.15-0.1,2.3-0.16,3.43c-0.06,1.1-0.31,2.02-1.32,2.77c-11.14,8.44-22.51,16.53-34.85,23.2c-0.37,0.21-1.1,0.26-1,1.3
                        c3.28-1.77,6.46-3.46,9.69-5.2c0.94,2.31,0.16,4.7,0.66,6.96C335.76,133.09,335.84,130.58,335.37,127.98z M490.95,160.78
                        c-2.49,3.53-5.65,8.05-8.91,12.69c2.94-0.18,5.68-0.34,8.48-0.5C490.7,168.29,490.85,163.78,490.95,160.78z M279.45,189.76
                        c-11.7,2.43-22.85,6.62-34.52,8.98c2.74,0.65,5.27-0.38,7.93-0.6c0.38,1.47-0.71,2.72-0.47,4.3c1.41-1.25,0.52-3.62,2.71-4.77
                        c-0.75,5.05-1.43,9.64-2.16,14.59c1.05-0.82,0.96-1.62,1.07-2.31c0.59-3.53,1.13-7.07,1.65-10.6c0.15-1.07,0.43-1.78,1.68-2.11
                        c3.09-0.77,6.18-1.62,9.2-2.66C270.88,193.08,275.15,191.38,279.45,189.76z M253.18,226.01c2.02,2.19,32.08-5.09,36.58-8.76
                        c-2.03,0.25-3.95,1.05-5.98,1.65c-0.06-2.03,0.75-3.75,0.4-5.79c-0.74,1.27-0.66,2.46-0.85,3.56c-0.4,2.33-0.38,2.33-1.55,2.41
                        c-0.12-1.25,0.68-2.46,0.28-3.83c-0.21,0.25-0.47,0.4-0.46,0.54c0.22,4.17-2.64,4.92-5.83,5.33c-0.74,0.1-1.47,0.28-2.21,0.44
                        C266.8,223.08,259.94,224.18,253.18,226.01z M164.73,192.9c0.68,4.28,2,7.88,3.06,11.94c0.69-1.41,1.19-2.5,1.77-3.55
                        c0.54-0.97,0.82-2.21,2.19-2.81c-0.32,3.8-4.73,6.33-2.61,10.69c1.18-3.95,3.25-7.29,5.05-10.82
                        C170.89,197.07,167.91,195.29,164.73,192.9z M169.33,117.98c-0.56-0.74-1.06-1.37-1.55-2.02c-2.31-3.06-2.3-3.06,0.75-5.31
                        c0.07-0.06,0.09-0.21,0.21-0.52c-2.62-0.63-4.33,1.41-6.99,1.72c1.35,1.58,2.22,2.89,3.39,3.87c1.3,1.1,0.87,1.69-0.24,2.46
                        c-1.07,0.75-2.49,1.03-3.31,2.15c2.55,4.14,3.62,5.03,5.96,5.05C165.44,121.85,165.44,121.85,169.33,117.98z M347.81,53.47
                        c-0.72,0.16-1.53,0.28-2.3,0.5c-1.75,0.52-2.59,0-2.52-1.91c0.04-0.97-0.19-1.93-0.62-2.86c-3.12,1.88-1.62,4.76-2.16,7.04
                        c1.56,0.9,3.3-1.05,4.37,0.62c0.85,1.32,0.32,3.03,0.57,4.5c2.13,0.47,3.75-0.01,4.78-1.96C346.24,58.51,348.84,55.56,347.81,53.47
                        z M558.64,72.73c3.9-0.37,7.23-0.22,10.54,0.1c0.91,0.09,1.78,0.25,2.15-0.91c0.32-1.02,1.09-2.02,0.19-3.06
                        c-0.93-1.07-2.08-1.66-3.61-1.4C564.35,68.06,561.66,70.12,558.64,72.73z M396.44,88.38c-0.26,0.88-0.5,1.71-0.31,2.77
                        c5.54-0.25,3,3.74,3.21,6.11c1.16,0.91,3.09-0.07,3.52,1.75c0.32,1.37-0.28,2.75-0.41,4.09c1.34,0.63,2.05,0.24,2.43-1.02
                        c0.32-1.1,0.72-2.19,1.13-3.28c0.4-1.05,0.1-1.75-0.74-2.49C402.34,93.76,399.5,91.14,396.44,88.38z M230.72,130.33
                        c-3.28-1.21-3.28-1.21-2-4.31c0.25-0.62,0.49-1.25,0.71-1.83c-1.53-1.65-3.18-1.6-5.21-0.63c4.17,1.94,0.87,4.15,0.34,6.46
                        c4.55,1.18,2.25,4.36,1.71,7.27c1.13-0.71,2.44-0.01,2.86-1.4C229.64,134.1,230.16,132.28,230.72,130.33z M225.89,132.57
                        c-0.68-0.38-1.1-0.77-1.62-0.88c-1.83-0.44-1.9-1.41-1.05-2.84c0.57-0.97,1.06-2.02,1.63-3.11c-1.44-0.84-2.36-2.8-4.52-1.3
                        c3.56,2.69-0.34,4.48-0.96,6.92c4.06,1.31,3.44,4.11,1.81,7.11C225.71,138.39,224.39,134.57,225.89,132.57z M329.32,224.4
                        c0.84-2.11,2.08-4.05,2.25-6.43c-2.21-1.12-4.39-2.22-6.51-3.31c-1.37,1.65-1.85,3.64-2.74,5.43c-0.63,1.28-0.16,1.84,1,2.22
                        C325.29,222.99,327.25,223.68,329.32,224.4z M320.53,190.22c-2.15-1.09-4.31-2.19-6.49-3.3c-1.41,1.65-1.9,3.7-2.8,5.54
                        c-0.6,1.22-0.1,1.72,0.97,2.08c2.02,0.68,4.02,1.4,6.08,2.11C319.15,194.49,320.36,192.58,320.53,190.22z M307.36,165.5
                        c0.85-2.11,2.09-4.05,2.25-6.42c-2.22-1.13-4.39-2.22-6.52-3.31c-1.32,1.68-1.84,3.65-2.72,5.45c-0.63,1.3-0.13,1.83,1.02,2.22
                        C303.35,164.09,305.31,164.78,307.36,165.5z M301.04,334.1c13.65-5.87,27.57-11.17,40.06-19.43
                        C328.23,322.14,314.25,327.32,301.04,334.1z M377.39,48.58c-1.25,2.69-2.41,5.23-3.65,7.91c1.38,0.01,2.77-0.5,3.72,0.21
                        c1.18,0.88,0,2.41,0.46,3.67c1.13,0.12,2.27,0.24,3.43,0.37C380.01,56.64,378.74,52.72,377.39,48.58z M304.09,93.69
                        c3.22,2.06,3.22,2.06,2.74,6.7c3.55,0.32,2.08,2.97,2.06,4.98c3.77-0.71,2.22-3.78,3.15-5.83c-3.46,0.28-3.84-0.22-2.87-3.34
                        c0.21-0.69,0.69-1.34,0.5-2.11C306.9,91.92,306.9,91.92,304.09,93.69z M297.63,135.87c0.65-2.09,1.97-3.86,2.36-6.08
                        c-2.24-1.13-4.45-2.24-6.71-3.39c-0.93,2.31-1.78,4.45-2.66,6.65C292.96,134,295.2,134.9,297.63,135.87z M384.93,65.76
                        c-0.01,1.35,0.13,2.38-0.06,3.36c-0.28,1.37,0.24,1.85,1.49,2c1.43,0.16,2.12,1.03,2.19,2.47c0.03,0.47-0.13,1.1,0.56,1.4
                        c0.97-1.6,1.5-3.36,2.13-5.06c0.16-0.44,0.29-1-0.26-1.27C389.05,67.77,387.35,66.34,384.93,65.76z M389.28,78.76
                        c0.15,0.15,0.24,0.26,0.34,0.34c0.18,0.1,0.37,0.24,0.56,0.24c2.62,0.13,3.27,1.72,2.84,3.97c-0.41,2.22,0.84,2.64,2.78,2.74
                        c-1.19-4.49-2.33-8.83-3.56-13.51C391.14,74.89,390.21,76.83,389.28,78.76z M417.39,137.38c3.05,1.22,3.09,1.24,2.43,4.48
                        c-0.28,1.41-0.54,2.64,1.22,3.3c0.91,0.34,1.07,1.28,0.72,2.08c-0.93,2.13,0.41,2.72,1.94,3.28c0.87-1.8,0.03-3.17-0.96-4.4
                        c-1.47-1.84-1.96-3.89-2.13-6.21c-0.18-2.25,0.21-4.58-0.57-7.18C419.05,134.47,418.23,135.91,417.39,137.38z M472.51,189.69
                        c1.27,0.57,2.25-0.07,3.24,0.01c0.62,0.06,1.1-0.09,1.21-0.77c0.41-2.81,0.81-5.62,1.21-8.44c-0.12-0.03-0.24-0.06-0.35-0.1
                        C476.07,183.46,474.33,186.5,472.51,189.69z M327.85,326.72c-7.29,4.21-14.94,7.71-22.35,11.67
                        C313.41,335.38,320.95,331.67,327.85,326.72z M297.39,165.93c1.4-3.33,0.87-4.05-3.19-4.48
                        C292.69,164.84,292.69,164.84,297.39,165.93z M319.33,224.83c1.38-3.33,0.87-4.05-3.19-4.46
                        C314.64,223.74,314.64,223.74,319.33,224.83z M305.13,192.59c-1.52,3.34-1.52,3.34,3.17,4.5
                        C309.7,193.78,309.2,193.06,305.13,192.59z M314.84,173.28c-0.01-0.18-0.01-0.35-0.03-0.53c-2.02-0.24-3.55-1.49-5.42-2.44
                        c-0.01,1.85-0.03,3.55-0.04,5.42C311.3,174.85,313.07,174.06,314.84,173.28z M287.97,136.3c0.5-0.93,0.78-1.84,0.9-2.84
                        c-1.19-0.65-2.25-1.32-3.55-1.59c-0.4,1.02-0.94,1.87-0.9,2.97C285.6,135.32,286.76,135.79,287.97,136.3z M139.65,146.32
                        c0.12,0.22,0.24,0.43,0.34,0.65c3.56-2.09,7.13-4.18,10.69-6.27c-0.12-0.19-0.22-0.38-0.34-0.57
                        C146.45,141.61,143.05,143.97,139.65,146.32z M311.88,154.79c-0.59,3.42-0.59,3.42,2.28,4.65
                        C314.73,155.93,314.73,155.93,311.88,154.79z M336.06,218.34c0.69-3.74,0.69-3.74-2.27-4.52
                        C333.21,217.04,333.21,217.04,336.06,218.34z M325.16,190.68c0.37-3.81,0.37-3.81-2.37-4.62
                        C322.14,189.21,322.14,189.21,325.16,190.68z M304.16,130.3c0.38-3.81,0.38-3.81-2.37-4.61
                        C301.14,128.85,301.14,128.85,304.16,130.3z M296.86,147.12c0.97,1.09,2.05,2.09,3.17,1.71c1.46-0.52,0.84-2.27,1.41-3.59
                        C299.66,145.58,298.33,146.37,296.86,147.12z M290.8,120.18c0.5-1.38,0.94-2.64,1.46-4.09c-1.9,0.47-3.34,1.38-5.17,2.24
                        C288.44,119.01,289.56,119.56,290.8,120.18z M308.24,178.75c0.99,0.46,1.65,1.68,2.8,1.18c1.28-0.56,0.69-2,1.19-3.11
                        C310.64,177.03,309.46,177.75,308.24,178.75z M323.14,204.49c-1.46,0.72-2.97,0.79-3.93,2.08
                        C321.87,208.39,322.73,208.73,323.14,204.49z M299.38,143.52c1.31-0.15,2.27-0.74,3.39-1.47c-1.18-0.69-2.13-1.27-3.22-1.91
                        C299.48,141.39,299.44,142.39,299.38,143.52z M378.54,287.09c-0.13-0.15-0.25-0.28-0.38-0.43c-2.02,1.93-4.03,3.86-6.04,5.79
                        c0.13,0.15,0.26,0.28,0.4,0.43C374.53,290.95,376.53,289.02,378.54,287.09z M334.61,231.09c-1.25-0.74-2.19-1.3-3.31-1.96
                        c0.09,1.31,0.16,2.27,0.25,3.39C332.58,232.05,333.4,231.65,334.61,231.09z M381.53,61.86c-0.03,1.34-0.54,2.4-0.32,3.59
                        c0.91-0.19,1.71-0.37,2.89-0.62C382.66,64.19,382.48,62.98,381.53,61.86z M386.56,60.79c-0.94-1.69-1.74-3.19-3.11-4.77
                        C383.79,58.26,384.37,59.9,386.56,60.79z M267.7,242.98c-0.66,1.62-0.99,3.28-0.63,5.05C267.74,246.43,267.9,244.74,267.7,242.98z"
                        />
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M157.2,289.17c0.16,0.81-0.13,1.4-0.97,1.41
                        c-1.52,0.03-2.55-0.79-3.02-2.21c-0.24-0.71,0.04-1.34,0.85-1.58C155.09,286.5,156.93,287.9,157.2,289.17z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M148.8,275.68c0.15,0.71,0.13,1.43-0.79,1.53
                        c-1.19,0.13-1.96-0.53-2.27-1.62c-0.26-0.9,0.09-1.74,1.06-1.85C148.1,273.59,148.45,274.68,148.8,275.68z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M151.97,277.87c1.16-0.13,1.83,0.4,2.11,1.46
                        c0.22,0.79,0.15,1.55-0.84,1.71c-1.05,0.19-1.88-0.26-2.24-1.28C150.69,278.86,151.12,278.21,151.97,277.87z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M356.12,249.2c-0.01-1.91,2.36-4.24,3.97-4.02
                        c0.84,0.12,1.1,0.77,0.66,1.3C359.56,247.92,358.25,249.28,356.12,249.2z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M354.3,250.96c-1.12,0.85-1.85,1.43-2.61,1.97
                        c-0.31,0.24-0.69,0.21-0.93-0.06c-0.15-0.16-0.24-0.62-0.13-0.72C351.52,251.29,352.38,250.29,354.3,250.96z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M249.6,104.64
                        c30.99-10.57,60.68-23.54,85.62-45.67c0.72,1.52-0.31,1.88-0.78,2.4c-5.86,6.48-13.28,10.98-20.46,15.77
                        c-12.04,8.04-25.31,13.66-38.63,19.06c-7.6,3.09-15.24,6.14-23.25,8.11C251.33,104.51,250.68,105.38,249.6,104.64z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M170.74,113.06
                        c12.72,1.59,25.47,1.99,38.23,0.59c12.7-1.4,25.1-4.42,37.82-8.17c-0.87,1.96-2.34,1.91-3.37,2.28
                        C221.66,115.48,182.19,118.45,170.74,113.06z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M183.83,101.52c-0.69,0.85-0.5,1.99-1.46,2.61
                        c-0.81-1,0.21-1.74,0.09-2.62c-2.21-0.79-1.18,2.02-2.58,2.28c-1-0.78-0.1-1.75,0.07-2.71c-1.75-0.16-0.72,1.97-1.84,2.08
                        c-0.84-0.25-0.43-0.81-0.5-1.22c-0.09-0.52,0.47-1.27-0.53-1.49c-0.87-0.19-1,0.49-1.31,0.99c-0.28,0.44-0.41,1.02-1.05,1.27
                        c-0.85-0.97,0.32-1.78,0.12-2.8c-1.78-0.77-3.68-1.47-5.36-2.65c-3.99-2.8-5.27-6.36-3.84-11.01c0.88-2.84,2.47-5.31,4.37-7.57
                        c6.9-8.17,15.55-14.19,24.59-19.67c13.62-8.26,28.02-14.9,42.85-20.65c15.33-5.96,31.02-10.64,47.15-13.81
                        c12.54-2.46,25.2-4.33,38.07-2.89c3.74,0.41,7.38,1.21,10.73,3c4.92,2.64,6.76,7.35,4.9,12.6c-1.5,4.21-4.27,7.57-7.38,10.66
                        c-8.48,8.41-18.46,14.71-28.83,20.45c-12.84,7.11-26.28,12.91-40.06,17.98c-16.34,6.02-33.05,10.66-50.19,13.62
                        C202.63,101.58,193.28,102.38,183.83,101.52z M253.49,65.62c-0.84,1.25-1.58,1.12-2.21,1.25c-2.21,0.43-4.43,0.77-6.62,1.24
                        c-6.79,1.41-12.63,4.81-18.2,8.76c-1.71,1.21-1.58,2.12,0.38,2.8c1.66,0.56,3.49,0.71,5.12,1.31c4.09,1.53,7.02,0.1,9.85-2.94
                        c3.65-3.93,7.46-7.74,12.16-10.5c0.52-0.29,0.96-0.84,1.63-0.38c0.62,0.4,0.62,0.99,0.54,1.62c-0.25,2.15-0.5,4.3-0.75,6.46
                        c-0.13,1.19-0.4,2.4,0.43,3.71c6.33-1.62,12.38-3.87,18.34-7.39c-4.23-3.58-9.39-2.72-13.6-4.37c0.18-0.93,0.57-1.13,1.03-1.35
                        c2.9-1.35,5.93-2.37,9.02-3.17c4.17-1.07,8.38-1.78,12.7-1.63c2.5,0.09,3.55-1.28,3.17-3.8c-0.19-1.28-0.43-2.56-0.59-3.84
                        c-0.18-1.4-0.91-1.99-2.33-1.99c-0.82,0-1.62,0.06-2.43,0.21c-8.21,1.6-15.3,5.42-21.48,11.01c-0.59,0.53-1.06,1.5-2.33,0.9
                        c0.5-1.31,1.3-2.38,2.15-3.43c3.93-4.83,8.38-9.1,13.47-12.7c0.93-0.66,2.13-1.24,2.22-2.66c-0.77-0.59-1.59-0.54-2.4-0.5
                        c-3.28,0.19-6.46,0.93-9.64,1.68c-3.03,0.71-5.08,2.56-6.2,5.39c-1.4,3.5-2.11,7.17-1.93,10.95c0.04,0.91-0.04,1.68-0.54,2.38
                        c-0.93-0.06-1.63-0.44-2.37-0.79c-2.58-1.24-5.14-2.53-7.76-3.65c-3.71-1.58-7.32-1.99-10.51,1.25c-1.24,1.25-2.77,2.22-3.99,3.49
                        c-1.66,1.74-3.27,3.56-4.12,6.46C234.82,67.94,243.58,64.88,253.49,65.62z M181.81,74.1c2.71-0.65,5.42,0.09,8.11,0.1
                        c0.78,0,1.41,0.46,1.84,1.12c0.22,0.34,0.09,0.72-0.18,0.91c-0.68,0.52-1.44,0.24-2.15,0.04c-0.26-0.06-0.52-0.19-0.74-0.35
                        c-1.97-1.37-7.4-1.58-9.17-0.01c-3.33,2.94-6.4,6.17-7.95,10.47c-1.21,3.36-0.1,5.98,3,7.74c3.68,2.09,7.77,2.27,11.82,2.61
                        c0.41,0.03,0.77-0.15,1.06-0.5c2.25-2.62,5.17-4.27,8.61-5.45c-1.06-0.84-1.97-1.52-2.64-2.47c-1.74-2.52-1.78-5.14,0.43-6.73
                        c1.8-1.3,1.9-2.75,1.87-4.64c-0.09-3.92,0.82-7.89,6.32-8.26c1.62-0.1,2.5-0.78,2.72-2.55c0.16-1.24,0.87-2.43,1.4-3.61
                        c1.03-2.31,2.77-3.64,5.34-3.03c3.12,0.75,4.71-0.37,5.77-3.37c0.94-2.69,2.96-4.55,6.23-4.23c1.46,0.15,2.94,0.15,4.42,0.21
                        c3.55-7.49,6.27-7.2,13.54-5.82c1.43-2.08,2.65-4.34,4.73-6.35c-0.74,0.16-1.15,0.19-1.52,0.34c-11.39,4.42-22.55,9.39-33.51,14.82
                        c-1.22,0.6-2.99,0.91-2.36,3.03c0.01,0.03-0.21,0.12-0.35,0.19c-0.16-0.09-0.34-0.21-0.53-0.25c-0.47-0.1-0.38-1.24-1.19-0.63
                        c-0.88,0.65,0.18,0.81,0.32,1.19c0.12,0.28,0.12,0.52-0.07,0.75c-0.26,0.34-0.62,0.29-0.91,0.12c-0.44-0.28-0.84-0.63-1.31-1.02
                        c-5.77,3.22-11.48,6.58-16.99,10.72c1.9,0.69,3.81,0.41,5.17,2.33c-2.84-0.44-5.34-2.46-7.99-0.28c2.93,0.77,6.36-0.29,8.46,2.38
                        c-0.07,0.38-0.13,0.77-0.21,1.16C189.44,72.92,185.65,70.37,181.81,74.1z M290.78,33.1c0.66-0.84,1.19-1.55,1.75-2.24
                        c1.97-2.43,3.43-2.75,6.21-1.35c2.06,1.05,3.89,2.43,5.33,4.24c3.53-2.99,4.64-2.9,7.46,0.53c0.85-1.71,1.32-3.72,4.08-4.46
                        c-2.25,2.37-3.43,4.62-2.78,7.46c3.14-0.12,4.08,0.79,4.17,4.02c0.15,5.3-2.81,8.86-6.6,12.06c-2.96,2.5-5.77,5.14-8.66,7.73
                        c0.43,0.16,0.71,0.13,0.93,0c6.08-3.59,12.09-7.29,17.65-11.66c4.4-3.46,8.6-7.13,11.69-11.87c1.13-1.74,1.96-3.59,2.02-5.7
                        c0.06-2.25-2.34-5.26-4.18-5.01c-3.71,0.52-7.36,1.25-10.57,3.4c-1.15,0.78-2.4,1.37-4.21,1.83c1.81-3.43,3.95-5.9,7.21-7.48
                        c-26.29-1.43-50.72,6.33-75.04,14.81c2.74-0.26,5.42-0.19,7.89,1.05c4.34-5.87,4.36-6.74,11.7-3.55c1.4-1.72,2.5-3.8,4.98-4.27
                        c2.43-0.47,4.21,1.16,6.24,1.93C282.85,28.69,285.91,27.5,290.78,33.1z M259.11,80.59c-3.18,0.97-6.3,2.12-9.52,2.89
                        c-8.88,2.11-17.75,4.25-26.94,4.61c-4.61,0.18-9.25,0.19-13.66-1.38c-5.36-1.93-6.76-5.93-3.86-10.84c0.38-0.65,0.82-1.25,1.3-1.83
                        c2.62-3.27,5.79-5.93,9.22-8.3c17.14-11.81,36.3-18.83,56.33-23.82c6.08-1.52,12.31-2.36,18.55-2.74c4.87-0.29,9.79-0.35,14.5,1.32
                        c3.39,1.21,5.73,3.24,5.51,7.4c2.21-4.15,1.02-7.73-3.06-9.67c-3.25-1.55-6.77-2.05-10.32-2.19c-11.72-0.49-23.01,1.94-34.18,5.15
                        c-11.35,3.27-22.24,7.66-32.74,13.04c-9.23,4.74-18.06,10.07-25.42,17.52c-2.16,2.19-4.11,4.56-5.3,7.45
                        c-1.68,4.09-0.49,7.15,3.49,9.11c2.03,1,4.21,1.56,6.45,1.87c9.58,1.32,19.05,0.13,28.31-2.18
                        C245.04,86.18,252.15,83.62,259.11,80.59z M220.54,92.38c-5.15,0.75-10.23-0.37-15.37-0.32c-3.97,0.03-6.52,1.94-9.64,4.36
                        C204.32,95.99,212.42,94.15,220.54,92.38z M188.36,96.52c0.94,0.03,1.94,0.63,2.77-0.1c2-1.78,4.25-3.24,6.42-4.83
                        C193.85,92.01,190.98,93.96,188.36,96.52z M318.53,29.15c2.99-1.25,5.98-2.46,9.6-3.27C324.33,24.56,320.52,25.94,318.53,29.15z
                        M199.43,92.04c-2.41,1.24-4.89,2.38-6.73,4.5C195.47,95.86,197.14,93.48,199.43,92.04z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M224.09,170.58
                        c-4.36,9.63-9.67,18.78-13.75,28.53C209.97,196.12,221.47,172.04,224.09,170.58z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M233.15,175.94
                        c-3.9,7.92-7.79,15.84-11.88,23.64C224.42,191.3,227.92,183.18,233.15,175.94z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M227.24,171.83
                        c-3.03,6.76-6.62,13.21-10,19.79C219.88,184.67,223.13,178.05,227.24,171.83z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M224.47,199c2.47-5.43,4.8-10.95,7.99-16.03
                        c0.21,0.1,0.43,0.21,0.63,0.31c-2.68,5.33-5.34,10.64-8.02,15.97C224.89,199.17,224.68,199.08,224.47,199z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M231.79,192.22c0.66-1.75,1.27-3.52,2.66-4.89
                        C234,189.18,233.37,190.94,231.79,192.22z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M205.45,185.66
                        c-0.19-2.33,0.16-3.14,1.68-4.27C207.1,182.96,206.47,184.14,205.45,185.66z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M230.02,250.74
                        c1.97-7.77,5.51-14.72,10.86-20.74C236.99,236.77,232.84,243.41,230.02,250.74z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M240.51,205.71
                        c-0.18,0.54-0.21,0.82-0.32,1.03c-3.19,5.26-5.86,10.81-8.66,16.66C231.01,220.83,238.15,206.83,240.51,205.71z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M213.85,213.1c0.93-2.46,1.87-4.9,3.43-7.04
                        c0.22,0.1,0.46,0.22,0.68,0.32c-1.16,2.34-2.33,4.68-3.49,7.01C214.26,213.3,214.05,213.2,213.85,213.1z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M236.75,216.51c1.15-1.85,1.8-4.03,3.49-5.52
                        c0.15,0.1,0.31,0.19,0.46,0.29c-1.13,1.87-2.25,3.72-3.39,5.59C237.12,216.76,236.94,216.63,236.75,216.51z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M226.37,140.84
                        c-3.77,7.32-7.91,14.44-11.72,22.13C213.96,160.44,223.84,141.8,226.37,140.84z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M223.77,149.32
                        c-2.33,4.67-4.62,9.32-7.1,13.88C218.63,158.36,220.68,153.56,223.77,149.32z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M219.12,163.96c0.5-1.99,1.13-3.92,2.75-5.3
                        C221.43,160.66,220.48,162.43,219.12,163.96z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M373.63,80.66c0.63-5.15,0.26-10.33,0.53-15.5
                        c1.13,5.42,0.65,10.85,0.35,16.3c-0.84-0.03-0.82-0.46-0.87-0.85C373.66,80.6,373.63,80.66,373.63,80.66z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M410.47,172.1
                        c-0.52-6.61-1.88-13.15-1.56-19.79C409.91,158.88,411.6,165.4,410.47,172.1z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M407.79,173.7
                        c-0.35-6.12-0.94-12.23-1.05-18.37C407.91,161.41,408.69,167.52,407.79,173.7z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M347.06,45.9c2.61-3.06,4.27-6.27,5.56-9.72
                        c1.06-2.81,1.22-5.68,1.12-8.6c-0.06-1.85-1.38-3.15-2.15-4.71c2.05,0.81,3,2.58,3.24,4.52c0.87,7.27-2.09,13.22-6.86,18.45
                        C347.9,45.89,347.71,45.85,347.06,45.9z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M195.68,184.8
                        c-2.25,4.53-4.52,9.13-6.74,13.62c-1.24,0-1.77-0.68-2.36-1.22c-2.78-2.59-5.52-5.24-8.33-7.8c-1.24-1.13-1.49-2.15-0.62-3.71
                        c1.83-3.28,3.89-6.39,5.99-9.48c0.9-1.31,1.68-0.77,2.49-0.01c1.8,1.65,3.56,3.36,5.37,5.01
                        C192.79,182.37,194.15,183.49,195.68,184.8z M184.89,179.74c-1.31,2.15-2.43,4.06-3.64,5.93c-0.56,0.87-0.4,1.43,0.32,2.09
                        c2.19,2,4.34,4.08,6.61,6.2c1.24-2.59,2.44-5.14,3.7-7.77C189.6,184.1,187.36,182.04,184.89,179.74z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M480.5,86.68c2.55,0.12,4.81,1.03,6.95,2.37
                        C485.14,88.27,482.83,87.48,480.5,86.68z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M179.43,145.78c3.72,3.5,7.17,6.36,9.95,9.89
                        c0.68,0.87,0.66,1.62,0.19,2.62c-1.55,3.31-4.2,5.87-6.01,9.01c-0.56,0.97-1.32,0.56-1.87,0.01c-2.94-2.93-5.86-5.89-8.76-8.85
                        c-0.62-0.63-0.54-1.35-0.12-2.06C174.95,152.99,177.07,149.57,179.43,145.78z M180.01,151.08c-1.43,1.99-2.68,3.72-3.96,5.49
                        c2.36,1.75,4.23,3.78,6.24,5.79c1.21-1.58,2.27-2.96,3.34-4.36C183.77,155.68,181.99,153.49,180.01,151.08z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M183.47,211.26c1.81-2.69,4.18-4.86,6.2-7.3
                        c0.6-0.74,1.59-0.77,2.38-0.09c2.53,2.16,5.36,3.97,7.64,6.43c1.02,1.09,1.15,1.91,0.1,3.14c-1.72,2-3.24,4.2-4.84,6.32
                        c-1.3,1.72-2.11,1.75-3.62,0.1C188.77,217.04,186.21,214.23,183.47,211.26z M186.86,211.21c2.13,2.08,4.24,4.14,6.51,6.35
                        c1.06-2.36,3.19-3.74,3.9-6.12c-2.24-1.63-4.11-3.64-6.48-5.03c-0.72,0.82-1.43,1.56-2.08,2.34
                        C188.08,209.52,187.49,210.36,186.86,211.21z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M197.14,228.06c2.89,2.36,5.59,4.61,8.08,7.11
                        c2,2.02,2,2.44,0.16,4.52c-1.93,2.18-3.84,4.37-5.77,6.57c-1.19-0.28-1.78-1.27-2.41-2.09c-2.21-2.83-4.33-5.73-6.49-8.6
                        c-0.9-1.19-0.84-2.16,0.46-3.09C193.15,231.09,195.06,229.6,197.14,228.06z M196.99,231.87c-1.22,1.05-2.36,1.93-2.99,3.25
                        c0.57,0.6,0.94,1.35,2,1.78c-0.9-1.69,0.01-2.62,0.93-3.56c3.7,1.59,4.65,3.99,2.53,6.23c-0.69,0.19-1.03-0.68-2.06-0.71
                        c0.9,1.02,1.55,1.75,2.24,2.53c1.3-1.1,2.64-1.93,3.65-3.19C201.23,236.06,199.36,233.83,196.99,231.87z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M154.18,103.75c2.21,1.49,3.39,4.05,5.9,5.18
                        C157.92,109.05,154.93,106.44,154.18,103.75z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M328.1,222.28c-1.43-0.21-2.11-1.6-3.43-1.8
                        c-0.35-0.04-0.52-0.47-0.5-0.81c0.07-1.28,0.9-2.3,1.69-3.08c0.69-0.68,1.41,0.41,2.09,0.77c0.96,0.52,1.6,1.16,1.05,2.44
                        C328.67,220.58,329,221.59,328.1,222.28z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M317.18,194.53c-1.55-0.21-2.3-1.56-3.65-1.9
                        c-0.6-0.15-0.52-1.05-0.21-1.62c0.44-0.82,0.91-1.62,1.35-2.37C318.69,190.1,318.75,190.47,317.18,194.53z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M306.25,163.4c-1.53-0.21-2.28-1.56-3.64-1.91
                        c-0.59-0.15-0.52-1.03-0.21-1.62c0.44-0.82,0.91-1.62,1.34-2.37C307.75,158.95,307.83,159.33,306.25,163.4z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M296.35,133.76
                        c-0.78-0.41-1.47-0.81-2.19-1.15c-0.69-0.34-1.44-0.69-1.21-1.6c0.26-0.96,0.37-2.15,1.28-2.66c0.91-0.52,1.56,0.52,2.27,0.94
                        c0.59,0.35,1.41,0.72,1.02,1.71C297.17,131.85,297.24,132.88,296.35,133.76z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M188.32,89.7c0.97-2.02,1.43-2.08,2.72-0.56
                        c0.07,0.09,0.18,0.21,0.28,0.21c0.57,0.01,1.16,0.07,1.15,0.82c-0.01,0.54-0.47,0.77-0.99,0.88c-0.72,0.15-1.15-0.01-1.34-0.81
                        C190.06,89.86,189.89,89.91,188.32,89.7z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M317.27,33.44c-0.21-0.77,0.19-1.15,0.62-1.49
                        c0.88-0.71,1.87-1.1,3.02-0.93c0.29,0.04,0.62,0.35,0.49,0.77c-0.35,1.12-1.55,0.94-2.31,1.44
                        C318.6,33.54,317.99,33.83,317.27,33.44z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M307.83,29.25c1.21-2.71,2.86-3.61,4.78-2.8
                        C311.36,27.84,309.82,28.4,307.83,29.25z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M323.68,37.13c-1.53,0.72-2.91,0.9-4.48,0.81
                        C320.15,35.92,322.06,35.57,323.68,37.13z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M323.05,34.8c-1.38,0.71-2.84,0.84-4.83,0.99
                        c1.13-1.66,2.38-1.97,3.68-2.15C322.61,33.54,323.02,33.94,323.05,34.8z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M305.62,28.44c0.87-2.56,2.25-3.43,4.03-2.86
                        C308.65,26.84,307.21,27.27,305.62,28.44z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M184.8,182.64c0.46-0.18,0.78,0.12,1.1,0.35
                        c0.71,0.53,1.41,1.06,2.09,1.62c2.52,2.09,2.62,3.36,0.59,5.92c-0.49,0.6-0.97,0.74-1.55,0.25c-1.24-1.05-2.56-2.03-3.65-3.21
                        C182.39,186.51,183.49,183.04,184.8,182.64z M185.27,184.17c-1.74,2.33-1.43,2.97,2.49,5.39c0.41-0.77,0.82-1.53,1.24-2.3
                        C187.94,185.94,186.71,184.94,185.27,184.17z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M181.84,159.75c-3.92-1.88-4.24-2.9-1.97-5.79
                        C183.21,155.71,183.74,157.2,181.84,159.75z M179.99,155.42c-1.25,1.55,0.32,1.87,0.87,2.55c0.57,0.72,1.1,0.68,1.47-0.31
                        C181.59,156.94,180.82,156.21,179.99,155.42z"/>
                    <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" d="M191.53,208.49c0.65-0.16,3.33,2.05,3.34,2.99
                        c0.01,1.38-0.97,2.34-2,3.11c-0.54,0.4-3.27-1.72-3.53-2.66C189.14,211.26,190.81,208.67,191.53,208.49z M191.62,209.71
                        c-0.72,0.68-1.19,1.22-1.27,2.06c0.69,0.6,1.37,1.22,2.06,1.83c0.56-0.54,1.03-1.02,1.24-1.84
                        C193.03,211.14,192.38,210.48,191.62,209.71z"/>
                </g>
            </svg>

            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default Sombrero;